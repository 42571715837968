import React from 'react';
import EventCard from 'components/events/EventCard';
import { EventListContainer, Placeholder, PlaceholderIcon } from './EventList.styled';
import { GetEventListPartsFragment } from 'elitegrad-common/src/generated/graphql';
import Spinner from 'elitegrad-common/src/components/SpinnerIsoType';
import { NoEventsPlaceholder } from 'elitegrad-common/src/assets/icons';

interface EventsProps {
  eventsList?: GetEventListPartsFragment[];
  selectedEventId?: string;
  onSelectEvent: (eventId: string) => void;
  dateFormater?: (date: Date) => string;
  loading?: boolean;
}

const isVirtual = (event_type: string) => event_type === 'Online';

const EventList: React.FC<EventsProps> = ({ eventsList, selectedEventId, onSelectEvent, dateFormater, loading }) => {
  if (loading) return <Spinner text="loading..." />;

  if (!eventsList?.length) {
    return (
      <Placeholder>
        <PlaceholderIcon src={NoEventsPlaceholder} />
        {'No Event created yet'}
      </Placeholder>
    );
  }

  return (
    <EventListContainer>
      {eventsList?.map(
        ({ event_id, title, capacity, image, event_type, start_datetime, show_event, address, event_url }) => (
          <EventCard
            key={event_id}
            title={title}
            capacity={capacity}
            picture={image || ''}
            isVirtual={isVirtual(event_type)}
            address={isVirtual(event_type) ? event_url : address}
            formatedDate={dateFormater ? dateFormater(start_datetime) : ''}
            isHidden={!show_event}
            isSelected={selectedEventId === event_id}
            onSelectEvent={() => onSelectEvent(event_id)}
          />
        ),
      )}
    </EventListContainer>
  );
};

export default EventList;
