import styled from 'styled-components';
import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import { colors } from 'elitegrad-common/src/theme';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 1rem;
`;

export const StyledButtons = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-left: -4rem;
  padding: 1.3rem;

  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: ${colors.gray_light_l10};
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 1rem;
`;

export const Container = styled.div`
  display: flex;

  max-height: 67rem;
  overflow: hidden;
`;

export const Title = styled.div`
  font-family: 'RobotoMedium';
  font-size: 2rem;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  padding: 3rem;
  padding-bottom: 0;

  width: 100%;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.gray_light_l10};
  margin: 2rem 0;
`;

export const RadioButtonsContainer = styled.div`
  display: flex;

  margin-bottom: 2rem;

  & > label {
    margin-right: 1rem;
  }
`;
