import Region from '../types/Region';

import { RegionParts } from '../generated/RegionParts';

const asRegion = (regionParts: RegionParts): Region => {
  return {
    id: regionParts.interest_region_selection_id,
    name: regionParts.value,
  };
};

export { asRegion };
