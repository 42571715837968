import React, { useState, SyntheticEvent, useCallback } from 'react';
import WithSeparator from 'react-with-separator';
import { StyledDotMenuIcon, StyledMenu, StyledOpenMenu } from './CandidateCardDotMenu.styled';

import { MenuMoreAddGroup, MessageCircle } from 'elitegrad-common/src/assets/vectors';
import HorizontalRuler from 'elitegrad-common/src/components/HorizontalRuler';
import GroupModalController from 'elitegrad-common/src/components/groups/GroupModalController';
import Candidate from 'elitegrad-common/src/types/Candidate';
import { ChatModal } from 'elitegrad-common/src/components/messaging';
import CandidateCardDotMenuOption from 'elitegrad-common/src/components/candidates/CandidateCardDotMenuOption';
import DownloadCVMenuOptionController from 'elitegrad-common/src/components/candidates/DownloadCVMenuOptionController';
import { candidatePath } from 'routes';

const CandidateCardDotMenu: React.FC<{ candidate: Candidate; refetch: () => void }> = ({ candidate, refetch }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenMessaging, setModalOpenMessaging] = useState(false);

  const handlePropagation = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const handleMenuClick = () => setMenuOpen((m) => !m);

  const handleOnBlur = () => setMenuOpen(false);

  const handleOnClickAddGroup = () => {
    setMenuOpen(false);
    setModalOpen((m) => !m);
  };

  const handleMenuMessagingClick = () => setModalOpenMessaging((m) => !m);

  return (
    <StyledMenu onClick={handlePropagation} onMouseLeave={handleOnBlur}>
      <StyledDotMenuIcon onClick={handleMenuClick} props={{ lay: menuOpen }} />
      {menuOpen && (
        <StyledOpenMenu>
          <WithSeparator separator={<HorizontalRuler />}>
            <CandidateCardDotMenuOption text={'Add to group'} icon={MenuMoreAddGroup} onClick={handleOnClickAddGroup} />
            <DownloadCVMenuOptionController
              onClick={() => setModalOpen(false)}
              candidateId={candidate.userId}
              cvExists={Boolean(candidate.hasCV)}
            />
            <CandidateCardDotMenuOption text={'Send Message'} icon={MessageCircle} onClick={handleMenuMessagingClick} />
          </WithSeparator>
        </StyledOpenMenu>
      )}

      {modalOpenMessaging && (
        <ChatModal
          setModalOpen={setModalOpenMessaging}
          candidateId={candidate.userId}
          generateCandidatePath={candidatePath}
        />
      )}
      {modalOpen && <GroupModalController refetch={refetch} setModalOpen={setModalOpen} candidates={[candidate]} />}
    </StyledMenu>
  );
};

export default CandidateCardDotMenu;
