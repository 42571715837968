import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import JobStatusDelete from './JobStatusDelete';

import DELETE_JOB from 'elitegrad-common/src/queries/deleteJob.gql';
import { DeleteJob, DeleteJobVariables } from 'elitegrad-common/src/generated/DeleteJob';
import { usePopper } from 'elitegrad-common/src/utils/usePopper';

interface JobStatusDeleteControllerProps {
  jobId: string;
}

const JobStatusDeleteController: React.FC<JobStatusDeleteControllerProps> = ({ jobId }) => {
  const { setPopperStatus } = usePopper('JobStatusDelete');

  const [deleteJob, { loading: deleteLoading }] = useMutation<DeleteJob, DeleteJobVariables>(DELETE_JOB, {
    refetchQueries: ['GetJobsList'],
  });

  const handleOnDelete = useCallback(() => {
    const variables = {
      jobId,
    };

    deleteJob({ variables });
    setPopperStatus(false);
  }, [deleteJob, jobId, setPopperStatus]);

  return <JobStatusDelete loading={deleteLoading} onDelete={handleOnDelete} />;
};

export default JobStatusDeleteController;
