import React from 'react';
import Profile from './Profile';
import { useAuth } from 'elitegrad-common/src/utils/react-auth';
import { useGetMyProfileRecruiterQuery } from 'elitegrad-common/src/generated/graphql';
import Page from '../Page';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';
import { useImpersonation } from 'elitegrad-common/src/utils/useImpersonation';

const ProfileController: React.FC = () => {
  const { user } = useAuth();
  const { data, loading } = useGetMyProfileRecruiterQuery({ variables: { userId: user?.sub || '' } });
  const { isImpersonationRunning } = useImpersonation();
  const dataUser = data?.recruit_recruiter[0];

  const userEmail = isImpersonationRunning() ? dataUser?.email : user?.email;

  if (!user || loading)
    return (
      <Page>
        <SpinnerIsoType text="Loading" />
      </Page>
    );
  return (
    <Profile
      fullname={dataUser?.fullname || ''}
      email={userEmail || ''}
      isManager={user.isManager || false}
      companyName={dataUser?.account?.company?.name || ''}
    />
  );
};

export default ProfileController;
