import gql from 'graphql-tag';

export default gql`
  mutation InsertEvent(
    $title: String!
    $image: String!
    $description: String!
    $capacity: Int!
    $timezone: String!
    $startDate: timestamp!
    $endDate: timestamp!
    $eventType: String!
    $address: String
    $link: String
    $userID: String!
    $companyID: String!
    $showEvent: Boolean!
  ) {
    insert_recruit_event(
      objects: {
        title: $title
        image: $image
        description: $description
        capacity: $capacity
        timezone: $timezone
        start_datetime: $startDate
        end_datetime: $endDate
        event_type: $eventType
        address: $address
        event_url: $link
        account_id: $companyID
        creator_id: $userID
        show_event: $showEvent
      }
    ) {
      returning {
        title
        image
        description
        capacity
        timezone
        start_datetime
        end_datetime
        event_type
        address
        event_url
        show_event
      }
    }
  }
`;
