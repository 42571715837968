import React from 'react';
import styled from 'styled-components';

import Sidebar from 'components/SideBar';
import { PopperContextProvider } from 'elitegrad-common/src/utils/usePopper';
import { colors } from 'elitegrad-common/src/theme';
import { PeopleIcons } from 'elitegrad-common/src/assets/vectors';
import { useImpersonation } from 'elitegrad-common/src/utils/useImpersonation';
import { useGetMyProfileRecruiterQuery } from 'elitegrad-common/src/generated/graphql';

const StyledPage = styled.div`
  display: flex;
  flex-direction: row;
`;

const ScrollContent = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;

  overflow: hidden;

  & > * {
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledContent = styled.div`
  position: relative;
  flex-grow: 1;
`;

const StyledFlexColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  max-height: 100vh;
`;

const LoginAsStyled = styled.div`
  text-align: center;
  background-color: ${colors.beta_700_gray_dark};
  color: ${colors.beta_50_white_gray};
  height: 6.5rem;
  z-index: 1000;
  font-family: 'RobotoBold';
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PeopleIcon = styled(PeopleIcons)`
  stroke: ${colors.beta_50_white_gray};
  fill: ${colors.beta_50_white_gray};
  margin-left: 1.5rem;
  width: 4.5rem;
  height: 4.5rem;
`;
export interface PageProps {
  extraSidebar?: React.ReactNode;
  headerComponent?: React.ReactNode;
  footer?: React.ReactNode;
}

const Page: React.FC<PageProps> = ({ extraSidebar, headerComponent, children, footer }) => {
  const { isImpersonationRunning, getImpersonationProps } = useImpersonation();
  const isImpersonating = isImpersonationRunning();
  const { data } = useGetMyProfileRecruiterQuery({
    variables: { userId: getImpersonationProps().userID || '' },
  });
  const dataUser = data?.recruit_recruiter[0];
  return (
    <PopperContextProvider>
      <StyledPage>
        <Sidebar />
        <StyledFlexColumn>
          {isImpersonating && (
            <LoginAsStyled>
              You are logged as {dataUser?.fullname || ''}
              <PeopleIcon />
            </LoginAsStyled>
          )}
          <ScrollContent>
            {extraSidebar}
            <StyledContent>
              <StyledHeader>{headerComponent}</StyledHeader>
              {children}
              {footer}
            </StyledContent>
          </ScrollContent>
        </StyledFlexColumn>
      </StyledPage>
    </PopperContextProvider>
  );
};

export default Page;
