import styled from 'styled-components';

export const RadioButtonGroupOptions = styled.div`
  display: flex;
`;

export const RadioButtonContainer = styled.div`
  margin-left: 1rem;
  :first-child {
    margin-left: 0;
  }
`;
