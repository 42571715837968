import { colors } from 'elitegrad-common/src/theme';
import styled from 'styled-components';

export const TitleCloseIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
`;

export const HorizontalSeparator = styled.hr`
  border: 0;
  height: 1px;
  background-color: ${colors.gray_light_l10};
  margin-top: 30px;
  margin-left: -24px;
  margin-right: -24px;
`;

export const ModalContainer = styled.div`
  position: fixed;
  background: white;
  width: 38%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  height: 67rem;
`;

export const FormWrapper = styled.div`
  padding: 21px 8%;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;
