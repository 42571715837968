import { FilterOption } from '../types/FilterValueType';
import { JobRequirementValueParts } from '../generated/JobRequirementValueParts';

const asFilterOption = ({ value, label, value_id }: JobRequirementValueParts): FilterOption | null => {
  if (value === null || value_id === null || label === null) return null;
  return {
    filterValue: value,
    displayValue: label,
    id: value_id,
  };
};
export { asFilterOption };
