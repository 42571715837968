import JobOpType from './JobOpType';
import { FilterValueType } from './FilterValueType';

interface JobRequirement {
  requirementType: JobOpType;
  id?: string;
  name: string;
  value: FilterValueType;
}

export const assertJobRequirementArray = (maybe: unknown): maybe is JobRequirement[] => {
  if (!(maybe instanceof Array)) return false;
  return maybe.every((mfo: unknown) => (mfo as JobRequirement).requirementType !== undefined);
};

export default JobRequirement;
