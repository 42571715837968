import styled, { css } from 'styled-components';

import { EGIsotype } from 'elitegrad-common/src/assets/vectors';
import colors from 'elitegrad-common/src/theme/colors';

const SIDEBAR_WIDTH = '6rem';

export const StyledNonFixedWrapper = styled.div`
  min-width: ${SIDEBAR_WIDTH};
`;

export const SidebarIsotype = styled(EGIsotype)`
  grid-area: isotype;

  width: 3rem;
  height: 3rem;
  margin: auto;

  & * {
    fill: ${colors.white};
    stroke: ${colors.white};
  }
`;

export const StyledSidebar = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 6rem auto 6rem;
  grid-template-areas:
    'isotype'
    'top-area'
    'bottom-area';

  height: 100vh;

  font-size: 1rem;
  line-height: 1.2rem;

  color: ${colors.white};
  background-color: ${colors.red};
`;

export const StyledTopArea = styled.div`
  grid-area: top-area;
`;

export const StyledBottomArea = styled.div`
  grid-area: bottom-area;
  align-self: end;
`;

interface StyledSidebarItemProps {
  active?: boolean;
}

export const StyledSidebarItem = styled.div<StyledSidebarItemProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: ${SIDEBAR_WIDTH};
  height: 7rem;
  padding: 1rem;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      color: ${colors.red};

      background-color: ${colors.white};
    `}
`;

export const StyledSidebarItemIcon = styled.div<{ styles: { active: boolean; fill: boolean; stroke: boolean } }>`
  margin-bottom: 0.4rem;

  & > {
    width: 100%;
    height: 2.1rem;
  }
  & > svg {
    height: 100%;
    min-width: 1.7rem;
  }

  & > svg > * {
    ${({ styles: { fill, active } }) =>
      fill &&
      css`
        fill: ${active ? colors.red : colors.white};
      `}

    ${({ styles: { stroke, active } }) =>
      stroke &&
      css`
        stroke: ${active ? colors.red : colors.white};
      `}
  }
`;

export const StyledSidebarItemText = styled.div`
  display: block;
  text-align: center;

  font-family: 'RobotoMedium';
`;
