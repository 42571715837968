import React from 'react';
import { useParams } from 'react-router-dom';
import EventDetail from './EventDetail';
import Spinner from 'elitegrad-common/src/components/SpinnerIsoType';
import { useGetEventDetailQuery } from 'elitegrad-common/src/generated/graphql';
import { timestampToLocalDate } from '../../../utils/dates';
import { EVENT_VIRTUAL } from '../../../utils/constants';
import Header from '../EventList/EventListHeader/EventListHeader';
import Page from '../../Page';
import { Container } from './EventDetail.styled';
import RelatedCandidatesController from '../../../components/RelatedCandidates';

interface RouteParamsProps {
  id: string;
}

const EventDetailController = () => {
  const params = useParams<RouteParamsProps>();
  const { data, loading } = useGetEventDetailQuery({
    variables: {
      eventID: params.id,
    },
  });

  const event = data?.recruit_event_by_pk || null;

  if (loading || !event) {
    return <Spinner fullPage text="loading..." />;
  }
  const {
    title,
    address,
    capacity,
    account: { company },
    description,
    end_datetime,
    event_id,
    image,
    show_event,
    event_type,
    event_url,
    start_datetime,
    event_registrations,
    timezone,
  } = event;
  return (
    <Page headerComponent={<Header eventID={event_id} isHidden={!show_event} onCreate={() => 0} />}>
      <Container>
        <EventDetail
          title={title}
          address={address || ''}
          capacity={capacity}
          company={company?.name || ''}
          description={description || ''}
          endDate={timestampToLocalDate(end_datetime, timezone)}
          startDate={timestampToLocalDate(start_datetime, timezone)}
          image={image || ''}
          isVirtual={event_type === EVENT_VIRTUAL}
          link={event_url || ''}
        />
        <RelatedCandidatesController candidatesUserIds={event_registrations.map((e) => e.user_id)} />
      </Container>
    </Page>
  );
};

export default EventDetailController;
