import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { Paths } from 'routes';
import { useAuth } from 'elitegrad-common/src/utils/react-auth';
import { AuthCallback, AuthCheckingSession, AuthDebug, AuthLogin, AuthLogout } from 'views/login';
import Candidate from 'views/CandidateDetail';
import CandidatesListController from 'views/candidates';
import EditJob from 'views/editJob';
import Job from 'views/jobs/JobDetail';
import Jobs from 'views/jobs/JobList';
import NewJob from 'views/newJob';
import { Messaging, MessagingNew } from 'views/messaging';
import { FilterContextProvider } from 'elitegrad-common/src/utils/useFilter';
import Company, { EditCompanyController } from 'views/company';
import EventList from 'views/events/EventList';
import NewEvent from 'views/events/NewEvent';
import EventDetail from 'views/events/EventDetail';
import EditEvent from 'views/events/EditEvent';
import Profile from './views/profile';
import ImpersonRoutes from 'elitegrad-common/src/components/ImpersonRoute';

const App: React.FC = () => {
  const { checkingSession, loginRequired, user } = useAuth();

  if (checkingSession) {
    return <AuthCheckingSession />;
  }

  if (loginRequired) {
    return <AuthLogin />;
  }

  return (
    <Switch>
      <Route exact path={Paths.authCallback} component={AuthCallback} />
      <Route exact path={Paths.authLogin} component={AuthLogin} />
      <Route exact path={Paths.authDebug} component={AuthDebug} />
      <Route exact path={Paths.authLogout} component={AuthLogout} />
      <Route exact path={Paths.imperson} component={ImpersonRoutes} />
      <Route
        exact
        path={Paths.candidates}
        component={() => (
          <FilterContextProvider>
            <CandidatesListController />
          </FilterContextProvider>
        )}
      />
      <Route exact path={Paths.candidate} component={Candidate} />
      <Route exact path={Paths.jobs} component={Jobs} />
      <Route exact path={Paths.newJob} component={NewJob} />
      <Route exact path={Paths.editJob} component={EditJob} />
      <Route exact path={Paths.job} component={Job} />
      <Route exact path={Paths.conversationNew} component={MessagingNew} />
      <Route exact path={Paths.conversations} component={MessagingNew} />
      <Route exact path={Paths.conversation} component={Messaging} />
      <Route exact path={Paths.company} component={Company} />
      <Route exact path={Paths.newEvent} component={NewEvent} />
      <Route exact path={Paths.events} component={EventList} />
      <Route exact path={Paths.event} component={EventDetail} />
      <Route exact path={Paths.editEvent} component={EditEvent} />
      <Route exact path={Paths.myProfile} component={Profile} />
      {user?.isManager && <Route exact path={Paths.editCompany} component={EditCompanyController} />}
      <Route exact path={Paths.root}>
        {user && <Redirect to={Paths.candidates} />}
      </Route>
    </Switch>
  );
};

export default App;
