import React from 'react';

import CandidateType from 'elitegrad-common/src/types/Candidate';
import { CandidatesGrid } from '../../../views/candidates';
import { CandidateCount, RaisedHandsCount } from './Candidates.styled';
import Candidate from 'elitegrad-common/src/types/Candidate';

export interface JobProps {
  editSelection: (candidate: Candidate, add: boolean) => void;
  selectedCandidates: Candidate[];
  candidates: CandidateType[];
  candidatesCount: number;
  onChangePaginationOffset: (offset: number) => void;
  refetch: () => void;
  hasCVCards?: boolean;
}

const Candidates: React.FC<JobProps> = ({
  candidates,
  candidatesCount,
  refetch,
  editSelection,
  selectedCandidates,
  hasCVCards,
}) => {
  return (
    <CandidateCount>
      <CandidatesGrid
        refetch={refetch}
        candidates={candidates}
        editSelection={editSelection}
        selectedCandidates={selectedCandidates}
        defaultCollapsedCards
        hasCVCards={hasCVCards}
      />
      <RaisedHandsCount>{`Raised hands (${candidatesCount})`}</RaisedHandsCount>
    </CandidateCount>
  );
};

export default Candidates;
