import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { asCandidateWithProfileImage } from 'elitegrad-common/src/transformers/candidates';
import GET_CANDIDATE from 'elitegrad-common/src/queries/getCandidate.gql';
import { GetCandidate, GetCandidateVariables } from 'elitegrad-common/src/generated/GetCandidate';
import CandidateDetail from 'elitegrad-common/src/components/candidate/CandidateDetail/CandidateDetail';
import CandidateHeader from 'elitegrad-common/src/components/candidate/CandidateDetail/CandidateHeader';
import Header from 'elitegrad-common/src/components/Header';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';
import Page from '../Page';
import ErrorView from 'elitegrad-common/src/components/ErrorView';
import { messagingPath } from 'routes';

const JobController: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const { data, loading, error, refetch } = useQuery<GetCandidate, GetCandidateVariables>(GET_CANDIDATE, {
    skip: !userId,
    variables: { admin: false, userId: userId || '' },
  });

  const candidate = data?.grad_profile.map((c) => asCandidateWithProfileImage(c))[0] || null;

  if (loading) {
    return (
      <Page headerComponent={<Header backLink />}>
        <SpinnerIsoType text="Loading candidate..." />
      </Page>
    );
  }

  if (error || !candidate) {
    const errorMessage = error?.message || '';

    return (
      <Page headerComponent={<Header backLink />}>
        <ErrorView
          message={`We are having troubles loading the candidate, please try again later\n\n ${errorMessage}`}
        />
      </Page>
    );
  }
  return (
    <Page
      headerComponent={
        <CandidateHeader candidate={candidate} messagingPath={messagingPath(candidate.userId)} refetch={refetch} />
      }
    >
      <CandidateDetail candidate={candidate} />;
    </Page>
  );
};

export default JobController;
