import styled from 'styled-components';
import { EditJobIcon } from 'elitegrad-common/src/assets/vectors';
import { colors } from 'elitegrad-common/src/theme';

export const StyledShowStatusLink = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 2.4rem;
  grid-template-areas: 'icon legend';

  cursor: pointer;

  margin: auto;
`;

export const StyledIcon = styled(EditJobIcon)`
  grid-area: icon;

  width: 2.4rem;
  height: 2.4rem;

  & * {
    fill: ${colors.black};
  }
`;

export const StyledText = styled.div`
  grid-area: legend;

  margin-left: 1rem;

  font-size: 1.4rem;
  line-height: 2.4rem;
`;
