import gql from 'graphql-tag';

import { JobRequirementParts } from './fragments.gql';

export default gql`
  mutation RemoveJobRequirements($jobId: String!) {
    delete_recruit_job_requirement(where: { job_posting_id: { _eq: $jobId } }) {
      returning {
        ...JobRequirementParts
      }
    }
  }
  ${JobRequirementParts}
`;
