import React from 'react';
import styled from 'styled-components';

import { EGCombinationMark, Student } from 'elitegrad-common/src/assets/images';
import colors from 'elitegrad-common/src/theme/colors';

const StyledLandingSection = styled.div`
  position: relative;

  padding: 7.4rem 16% 0;
  background-color: ${colors.gray_extraLight};

  background-image: url(${Student});
  background-repeat: no-repeat;
  background-position: center bottom 0;

  @media only screen and (min-width: 900px) {
    // ipad landscape
    background-position: bottom;
    background-size: 100%;
  }

  @media only screen and (min-width: 900px) and (orientation: portrait) {
    // ipad pro portrait
    background-size: 150%;
  }

  @media only screen and (min-width: 1200px) {
    // ipad pro landscape
    background-position: bottom;
    background-size: 140%;
  }

  @media only screen and (min-width: 1400px) {
    // Laptop L
    background-size: 130%;
  }

  @media only screen and (min-width: 1600px) {
    // 4K
    background-size: 80%;
  }

  @media only screen and (max-height: 800px) {
    background-size: 80%;
  }

  @media only screen and (max-height: 700px) {
    background-size: 50%;
  }

  @media only screen and (max-height: 600px) {
    background-size: 40%;
  }

  @media only screen and (max-height: 500px) {
    background-image: none;
  }
`;

const LandingSection: React.FC = (props) => {
  return (
    <StyledLandingSection {...props}>
      <img src={EGCombinationMark} alt="EliteGrad combination mark" />
      <div
        style={{
          marginTop: '3.8rem',
          textTransform: 'uppercase',
          fontSize: '1.6rem',
          fontWeight: 700,
          lineHeight: '2.2rem',
          letterSpacing: '0.2rem',
        }}
      >
        Top Students {'&'} Top Companies, Together.
      </div>
      <div
        style={{
          marginTop: '1.8rem',
          fontSize: '3.2rem',
          fontWeight: 800,
          lineHeight: '4.8rem',
        }}
      >
        <span style={{ color: colors.red }}>Top companies </span>
        look for top students
      </div>
      <div style={{ marginTop: '1.8rem', fontSize: '1.8rem', fontWeight: 500, lineHeight: '2.7rem' }}>
        <span style={{ color: colors.red }}>EliteGrad enables companies </span>
        to narrow down the pool to the most elite students at Universities across North America.
      </div>
    </StyledLandingSection>
  );
};

export default LandingSection;
