import gql from 'graphql-tag';

export default gql`
  mutation UpdateEvent(
    $eventID: String!
    $title: String!
    $image: String!
    $description: String!
    $capacity: Int!
    $timezone: String!
    $startDate: timestamp!
    $endDate: timestamp!
    $eventType: String!
    $address: String
    $link: String
  ) {
    update_recruit_event_by_pk(
      pk_columns: { event_id: $eventID }
      _set: {
        title: $title
        image: $image
        description: $description
        capacity: $capacity
        timezone: $timezone
        start_datetime: $startDate
        end_datetime: $endDate
        event_type: $eventType
        address: $address
        event_url: $link
      }
    ) {
      title
      image
      description
      capacity
      timezone
      start_datetime
      end_datetime
      event_type
      address
      event_url
    }
  }
`;
