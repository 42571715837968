import React from 'react';
import StatusHidden from 'views/jobs/JobDetail/JobStatusHidden/JobStatusHidden';
import { useEventStatusHiddenMutation } from './EventStatusHidden.hook';
import { usePopper } from 'elitegrad-common/src/utils/usePopper';

interface EventStatusHiddenController {
  eventID: string;
  isHidden: boolean;
}

const EventStatusHiddenController: React.FC<EventStatusHiddenController> = ({ eventID, isHidden }) => {
  const { popperOpen, togglePopper, setPopperStatus } = usePopper('JobStatusHidden');
  const [hideEvent] = useEventStatusHiddenMutation();
  const onClickHide = () => {
    hideEvent({
      variables: {
        eventID,
        show_event: isHidden,
      },
      refetchQueries: ['GetEventList'],
    });
  };

  const onSave = () => {
    onClickHide();
    setPopperStatus(false);
  };

  return (
    <StatusHidden
      hidden={isHidden}
      popperOpen={popperOpen}
      setPopperStatus={setPopperStatus}
      togglePopper={togglePopper}
      onSave={onSave}
    />
  );
};

export default EventStatusHiddenController;
