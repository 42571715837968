import { INITIAL_OFFSET } from 'elitegrad-common/src/components/pagination/PaginationState';
import CandidateType from 'elitegrad-common/src/types/Candidate';

export type StateInterface = {
  showCandidates: boolean;
  showCVs: boolean;
  resultsCount: number;
  paginationOffset: number;
  selectedCandidates: CandidateType[];
};

type ActionInterface =
  | { type: 'showToggleCVs' }
  | { type: 'showToggleCandidates' }
  | { type: 'addCandidateToSelected'; payload: CandidateType }
  | { type: 'removeCandidateFromSelected'; payload: CandidateType }
  | { type: 'setPaginationOffset'; payload: number }
  | { type: 'setResultsCount'; payload: number }
  | { type: 'removeAllCandidates' };

export const initialState: StateInterface = {
  showCandidates: true,
  showCVs: false,
  resultsCount: 0,
  paginationOffset: INITIAL_OFFSET,
  selectedCandidates: [],
};

const reducer = (state: StateInterface = initialState, action: ActionInterface) => {
  switch (action.type) {
    case 'showToggleCandidates':
      return {
        ...state,
        showCandidates: true,
        showCVs: false,
        paginationOffset: INITIAL_OFFSET,
      };
    case 'showToggleCVs':
      return {
        ...state,
        showCandidates: false,
        showCVs: true,
        paginationOffset: INITIAL_OFFSET,
      };
    case 'addCandidateToSelected':
      return {
        ...state,
        selectedCandidates: [...state.selectedCandidates, action.payload],
      };
    case 'removeCandidateFromSelected':
      return {
        ...state,
        selectedCandidates: state.selectedCandidates.filter(({ userId }) => userId !== action.payload.userId),
      };
    case 'removeAllCandidates':
      return {
        ...state,
        selectedCandidates: [],
      };
    case 'setPaginationOffset':
      return { ...state, paginationOffset: action.payload };
    case 'setResultsCount':
      return { ...state, resultsCount: action.payload };
    default:
      throw new Error();
  }
};
export default reducer;
