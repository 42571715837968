import React from 'react';
import RequirementsSideBar from './RequirementsSideBar';
import JobRequirement, { assertJobRequirementArray } from 'elitegrad-common/src/types/JobRequirement';
import { FilterContextProvider } from 'elitegrad-common/src/utils/useFilter';
import { filterAsJobRequirement } from 'elitegrad-common/src/transformers/jobRequirements';
import { FilterValueType } from 'elitegrad-common/src/types/FilterValueType';

interface RequirementsSideBarControllerProps {
  value?: JobRequirement[];
  onChange: (requirements: JobRequirement[]) => void;
}

const filterValuesToJobRequirements = (newFilterValues: { [x: string]: FilterValueType }) => {
  const jobRequirements = Object.entries(newFilterValues)
    .map(([key, value]) => filterAsJobRequirement(value, key))
    .filter((e) => e !== null);
  return assertJobRequirementArray(jobRequirements) ? jobRequirements : [];
};

const RequirementsSideBarController: React.FC<RequirementsSideBarControllerProps> = ({ onChange, value }) => {
  const handleChange = ({ values }: { values: { [x: string]: FilterValueType } }) =>
    onChange(filterValuesToJobRequirements(values));

  return (
    <FilterContextProvider onChange={handleChange} initialValue={value} persistState={false}>
      <RequirementsSideBar />
    </FilterContextProvider>
  );
};

export default RequirementsSideBarController;
