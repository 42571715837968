import styled from 'styled-components';
import { Eye, HideJobIcon } from 'elitegrad-common/src/assets/vectors';
import { colors } from 'elitegrad-common/src/theme';

export const StyledShowStatus = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 2.4rem;
  grid-template-areas: 'icon legend';
  align-items: center;

  cursor: pointer;

  margin: auto;
`;

export const StyledText = styled.div`
  grid-area: legend;

  margin-left: 1rem;

  font-size: 1.4rem;
  line-height: 2.4rem;
`;

export const StyledEyeOn = styled(Eye)`
  grid-area: icon;

  width: 2.4rem;
`;

export const StyledEyeOff = styled(HideJobIcon)`
  grid-area: icon;

  width: 2.4rem;
`;

export const StyledPopperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  width: 100%;
  padding: 0 !important;
  margin: 0;

  & > div:first-child {
    margin: auto;
  }

  & > div:last-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 1.5rem 2rem;
    min-width: 3.5rem;
    min-height: 7rem;

    background-color: ${colors.gray_extraLight};
  }
`;

export const Container = styled.div`
  margin: 2rem;
`;
