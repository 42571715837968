export const Paths = {
  root: '/',
  myProfile: '/profile',
  candidates: '/candidates',
  candidate: '/candidates/:userId',
  company: '/company',
  editCompany: '/company/edit',
  events: '/events',
  event: '/events/:id',
  editEvent: '/events/:id/edit',
  newEvent: '/events/new',
  jobs: '/jobs',
  job: '/jobs/:id',
  editJob: '/jobs/:id/edit',
  newJob: '/jobs/new',
  conversations: '/conversations',
  conversation: '/conversations/:id',
  conversationNew: '/conversations/new',
  authCallback: '/auth/callback',
  authLogin: '/auth/login',
  authDebug: '/auth/debug',
  authLogout: '/auth/logout',
  imperson: '/imperson/:id',
};

export const candidatePath = (userId: string) => `/candidates/${userId}`;

export const jobPath = (id: string) => `/jobs/${id}`;

export const messagingPath = (id: string) => `/conversations/${id}`;

export const editJobPath = (id: string) => `${jobPath(id)}/edit`;

export const eventPath = (id: string) => `/events/${id}`;

export const editEventPath = (id: string) => `${eventPath(id)}/edit`;

export const goToAdminApp = process.env.REACT_APP_LINK_ADMIN || '';
