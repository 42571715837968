import React, { useState } from 'react';

import { useAuth } from 'elitegrad-common/src/utils/react-auth';
import Modal from 'elitegrad-common/src/components/Modal';

import Company from './Company';

import { useGetCompanyQuery } from 'elitegrad-common/src/generated/graphql';
import Page from '../Page';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';
import styled from 'styled-components';
import { colors } from 'elitegrad-common/src/theme';
import EditCompanyController from './EditCompanyController';

const StyledNotSetYet = styled.div`
  position: relative;

  color: ${colors.gray_dark};

  font-size: 1.4rem;
  margin: 20rem auto;

  max-width: 50rem;

  text-align: center;
`;

const StyledModal = styled.div`
  &&& > * > * {
    width: 46vw;
    height: 90vh;
  }
`;

const CompanyController: React.FC = () => {
  const { user } = useAuth();

  const [editCompanyModalOpen, setEditCompanyModal] = useState(false);
  const { data, loading } = useGetCompanyQuery({
    skip: !user?.sub,
    variables: {
      id: user?.sub || '',
    },
  });

  const company = data?.recruit_company[0] || null;

  if (loading || !user)
    return (
      <Page>
        <SpinnerIsoType text="Loading" />
      </Page>
    );
  if (!company)
    return (
      <Page>
        <StyledNotSetYet>
          Your company details for this page hasn&apos;t been set yet, please contact EG administrators
        </StyledNotSetYet>
      </Page>
    );

  return (
    <>
      <Company
        phone={company.phone || ''}
        photo={company.photo || ''}
        num_employees={company.num_employees || ''}
        name={company.name}
        website={company.website || ''}
        address={company.address || ''}
        description={company.description || ''}
        industry={company.industry || ''}
        subindustries={company.subindustries?.map((i) => i.value) || ''}
        logo={company.logo || ''}
        toggleEdit={setEditCompanyModal}
      />
      {editCompanyModalOpen && (
        <StyledModal>
          <Modal crossPosition={{ top: '2.3rem', right: '1.1rem' }} setModalOpen={setEditCompanyModal}>
            <EditCompanyController closeModal={() => setEditCompanyModal(false)} />
          </Modal>
        </StyledModal>
      )}
    </>
  );
};

export default CompanyController;
