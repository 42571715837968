import React from 'react';

import Header from 'elitegrad-common/src/components/Header';
import JobStatusActiveController from '../JobDetail/JobStatusActive/JobStatusActiveController';
import JobStatusHiddenController from '../JobDetail/JobStatusHidden';
import JobStatusDeleteController from '../JobDetail/JobStatusDelete';
import JobStatusEdit from '../JobDetail/JobStatusEdit';

import { OptionsSection, Title, HeaderButton, JobOptions } from './JobHeader.styled';

interface JobHeaderProps {
  jobId: string;
  isHidden: boolean;
  isActive: boolean;
  hasCandidates: boolean;
  setModalOpen: (s: boolean) => void;
  hasBackLink?: boolean;
  title?: string;
  onCreateJob: () => void;
  onEditJob?: () => void;
}

const JobHeader: React.FC<JobHeaderProps> = ({
  setModalOpen,
  jobId,
  isHidden,
  isActive,
  hasCandidates,
  hasBackLink = true,
  title,
  onCreateJob,
  onEditJob,
}) => (
  <Header backLink={hasBackLink}>
    <Title>{title}</Title>
    <OptionsSection>
      <JobOptions isJobSelected={Boolean(jobId)}>
        <JobStatusEdit onEditJob={onEditJob} />
        <JobStatusHiddenController jobId={jobId} isHidden={isHidden} />
        <JobStatusActiveController
          setModalOpen={setModalOpen}
          jobId={jobId}
          isActive={isActive}
          hasCandidates={hasCandidates}
        />
        {!isActive && <JobStatusDeleteController jobId={jobId} />}
      </JobOptions>
      <HeaderButton onClick={() => onCreateJob()}>Create new job</HeaderButton>
    </OptionsSection>
  </Header>
);

export default JobHeader;
