import { asFilterOption } from './jobRequirementValue';

import { JobRequirementParts } from '../generated/JobRequirementParts';
import { recruit_job_requirement_insert_input } from '../generated/globalTypes';
import JobOpType from '../types/JobOpType';
import JobRequirement from '../types/JobRequirement';
import {
  assertFilterOption,
  assertRangeType,
  assertBoolean,
  assertRangeBooleanType,
  FilterOption,
  FilterValueType,
  RangeAndBool,
  RangeType,
} from '../types/FilterValueType';

const asJobRequirementParts = (
  jobRequirement: JobRequirement,
  jobId: string,
): recruit_job_requirement_insert_input | null => {
  const type = jobRequirement.requirementType;
  const baseInput = {
    job_posting_id: jobId,
    requirement_name: jobRequirement.name,
    requirement_type: jobRequirement.requirementType,
  };

  if (JobOpType.OPTIONS && assertFilterOption(jobRequirement.value)) {
    const data = jobRequirement.value.map((filterOption) => ({
      value: filterOption.filterValue,
      label: filterOption.displayValue,
      value_id: filterOption.id,
    }));

    return {
      ...baseInput,
      values: { data },
    };
  }
  if (JobOpType.RANGE && assertRangeType(jobRequirement.value)) {
    return {
      ...baseInput,
      max_range_value: jobRequirement.value[1],
      min_range_value: jobRequirement.value[0],
    };
  }
  if (JobOpType.BOOLEAN && assertBoolean(jobRequirement.value)) {
    return {
      ...baseInput,
      boolean_value: jobRequirement.value,
    };
  }
  if (JobOpType.RANGE_BOOLEAN && assertRangeBooleanType(jobRequirement.value)) {
    return {
      ...baseInput,
      boolean_value: jobRequirement.value.bool,
      max_range_value: jobRequirement.value.range[1],
      min_range_value: jobRequirement.value.range[0],
    };
  }
  console.warn(`Unknown JobRequirementType: ${type}`); // eslint-disable-line no-console
  return null;
};
//TODO: To delete
const asJobRequirement = (jobRequirementParts: JobRequirementParts): JobRequirement | null => {
  const type = jobRequirementParts.requirement_type;

  switch (type) {
    case JobOpType.OPTIONS:
      const filterOptions = (jobRequirementParts.values || [])
        .map(asFilterOption)
        .filter((v) => v !== null) as FilterOption[];
      return {
        name: jobRequirementParts.requirement_name,
        requirementType: JobOpType.OPTIONS,
        value: filterOptions,
        id: jobRequirementParts.job_requirement_id,
      };
    case JobOpType.RANGE:
      return {
        name: jobRequirementParts.requirement_name,
        requirementType: JobOpType.RANGE,
        value: [jobRequirementParts.min_range_value as number, jobRequirementParts.max_range_value as number],
        id: jobRequirementParts.job_requirement_id,
      };
    case JobOpType.BOOLEAN:
      return {
        name: jobRequirementParts.requirement_name,
        requirementType: JobOpType.BOOLEAN,
        value: jobRequirementParts.boolean_value as boolean,
        id: jobRequirementParts.job_requirement_id,
      };
    case JobOpType.RANGE_BOOLEAN:
      return {
        name: jobRequirementParts.requirement_name,
        requirementType: JobOpType.RANGE_BOOLEAN,
        value: {
          bool: jobRequirementParts.boolean_value as boolean,
          range: [jobRequirementParts.min_range_value as number, jobRequirementParts.max_range_value as number],
        },
        id: jobRequirementParts.job_requirement_id,
      };
  }
  console.warn(`Unknown JobRequirementType: ${type}`); // eslint-disable-line no-console
  return null;
};

const filterOptionAsJobRequirement = (options: FilterOption[], columnName: string): JobRequirement => {
  return {
    requirementType: JobOpType.OPTIONS,
    name: columnName,
    value: options,
  };
};

const filterRangeAsJobRequirement = (range: RangeType, columnName: string): JobRequirement => {
  return {
    requirementType: JobOpType.RANGE,
    value: range,
    name: columnName,
  };
};

const filterRangeBoolAsJobRequirement = (value: RangeAndBool, columnName: string): JobRequirement => {
  return {
    requirementType: JobOpType.RANGE_BOOLEAN,
    value,
    name: columnName,
  };
};

const filterBoolAsJobRequirement = (value: boolean, columnName: string): JobRequirement => {
  return {
    requirementType: JobOpType.BOOLEAN,
    value,
    name: columnName,
  };
};

const filterAsJobRequirement = (filterValue: FilterValueType, columnName: string) => {
  if (assertFilterOption(filterValue)) return filterOptionAsJobRequirement(filterValue, columnName);
  if (assertRangeType(filterValue)) return filterRangeAsJobRequirement(filterValue, columnName);
  if (assertRangeBooleanType(filterValue)) return filterRangeBoolAsJobRequirement(filterValue, columnName);
  if (assertBoolean(filterValue)) return filterBoolAsJobRequirement(filterValue, columnName);

  return null;
};

export { asJobRequirement, filterAsJobRequirement, asJobRequirementParts };
