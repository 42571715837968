import styled from 'styled-components';
import colors from 'elitegrad-common/src/theme/colors';

export const StyledCount = styled.div`
  padding-left: 0.8rem;
  margin: 2rem 0;

  font-size: 1.2rem;
  line-height: 1.2rem;

  color: ${colors.gray_dark};
`;

export const CandidateCount = styled.div`
  min-height: 15rem;
`;

export const RaisedHandsCount = styled.div`
  font-family: 'RobotoMedium';
  margin-right: 1rem;
  font-size: 1.2rem;
  line-height: 1.2rem;

  color: ${colors.gray_dark};

  padding: 2rem 0;
`;
