import styled from 'styled-components';
import { CircleSlashRed, ActivateIcon } from 'elitegrad-common/src/assets/vectors';
import { colors } from 'elitegrad-common/src/theme';
import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import SecondaryButton from 'elitegrad-common/src/components/SecondaryButton';

export const StyledShowStatus = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 2.4rem;
  grid-template-areas: 'icon legend';

  cursor: pointer;

  margin: auto;
`;

export const StyledText = styled.div<{ isActive?: boolean }>`
  grid-area: legend;

  margin-left: 1rem;

  font-size: 1.4rem;
  line-height: 2.4rem;

  color: ${({ isActive }) => (isActive ? colors.red : colors.black)};
`;

export const StyledIconCircleSlashRed = styled(CircleSlashRed)`
  grid-area: icon;

  width: 2.2rem;
`;

export const StyledIconUploadCloud = styled(ActivateIcon)`
  grid-area: icon;

  align-self: center;
  width: 2.4rem;
`;

export const StyledPopperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0 !important;

  height: 100%;
  width: 100%;

  & > div {
    padding: 0 0.5;
  }

  & > div:first-child {
    margin: auto;
    font-family: 1.6rem;
  }

  & > div:last-child {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    width: 100%;
    min-height: 7rem;

    background-color: ${colors.gray_extraLight};
  }
`;

export const StyledDismissAndText = styled(PrimaryButton)`
  padding: 0 0.5rem;
  min-width: 9rem;
  max-width: 12rem;
`;

export const StyledDismiss = styled(PrimaryButton)`
  min-width: 9rem;
`;

export const StyledCancel = styled(SecondaryButton)`
  min-width: 9rem;
`;
