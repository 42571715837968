import gql from 'graphql-tag';

import { SubIndustryParts } from './fragments.gql';

export default gql`
  mutation CleanInsertCompanySubIndustry(
    $companyId: Int
    $subIndustries: [recruit_company_subindustry_insert_input!]!
  ) {
    delete_recruit_company_subindustry(where: { company_id: { _eq: $companyId } }) {
      returning {
        ...SubIndustryParts
      }
    }
    insert_recruit_company_subindustry(objects: $subIndustries) {
      returning {
        ...SubIndustryParts
      }
    }
  }
  ${SubIndustryParts}
`;
