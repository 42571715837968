import gql from 'graphql-tag';

import { JobParts } from './fragments.gql';

export default gql`
  query GetJob($id: String!) {
    recruit_job_posting(where: { job_posting_id: { _eq: $id } }) {
      ...JobParts
    }
  }
  ${JobParts}
`;
