import gql from 'graphql-tag';

export default gql`
  mutation HideEvent($eventID: String!, $show_event: Boolean!) {
    update_recruit_event_by_pk(_set: { show_event: $show_event }, pk_columns: { event_id: $eventID }) {
      event_id
      show_event
    }
  }
`;
