import { HiddenEye } from 'elitegrad-common/src/assets/icons';
import React, { Dispatch, SetStateAction } from 'react';
import Linkify from 'react-linkify';

import {
  RaisedHandsCount,
  Title,
  Description,
  ActiveTag,
  ExpirationTag,
  CardContainer,
  JobState,
  Region,
  IntershipTag,
  EditIcon,
  HiddenTag,
} from './JobCard.styled';

export interface JobCardProps {
  id: string;
  region: string;
  title: string;
  description: string;
  isActive: boolean;
  isExpired: boolean;
  isHidden: boolean;
  relativeDeadline: string | null;
  candidatesUserIds: string[];
  isInternship: boolean;
  isSelected?: boolean;
  onSelectCard?: Dispatch<SetStateAction<string>>;
  onEditJob?: () => void;
}

const RaisedHands: React.FC<{ candidatesUserIds: string[] }> = ({ candidatesUserIds }) => {
  const description = `${candidatesUserIds.length} Raised Hand${candidatesUserIds.length !== 1 ? 's' : ''}`;

  return <RaisedHandsCount>{description}</RaisedHandsCount>;
};

const JobCard: React.FC<JobCardProps> = ({
  id,
  relativeDeadline,
  isExpired,
  isInternship,
  isActive,
  isHidden,
  title,
  description,
  region,
  candidatesUserIds,
  isSelected,
  onSelectCard,
  onEditJob,
}) => (
  <CardContainer isSelected={isSelected} onClick={() => onSelectCard && onSelectCard(id)}>
    <EditIcon selected={isSelected} onClick={() => onEditJob && onEditJob()} />
    <JobState>
      <ActiveTag isActive={isActive}>{isActive ? 'Active' : 'Inactive'}</ActiveTag>
      <ExpirationTag>{isExpired ? 'Expired' : relativeDeadline}</ExpirationTag>
      {isHidden && (
        <HiddenTag>
          <HiddenEye /> <span>HIDDEN</span>
        </HiddenTag>
      )}
    </JobState>
    <Region>{region}</Region>
    <Title>{title}</Title>
    <IntershipTag isIntern={isInternship}>{isInternship ? 'Intern' : 'Full time'}</IntershipTag>
    <Description>
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )}
      >
        {description}
      </Linkify>
    </Description>
    <RaisedHands candidatesUserIds={candidatesUserIds} />
  </CardContainer>
);

export default JobCard;
