import gql from 'graphql-tag';

export default gql`
  query GetEvent($id: String!) {
    recruit_event_by_pk(event_id: $id) {
      event_id
      title
      image
      description
      capacity
      timezone
      start_datetime
      end_datetime
      event_type
      address
      event_url
    }

    recruit_company(where: { account: { recruiters: { user_id: { _eq: $id } } } }) {
      company_id
    }
  }
`;
