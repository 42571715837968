import React, { useState } from 'react';
import HeaderPopper from 'elitegrad-common/src/components/HeaderPopper';
import { usePopper } from 'elitegrad-common/src/utils/usePopper';

import {
  StyledPopperContent,
  StyledShowStatus,
  StyledText,
  StyledDismissAndText,
  StyledIconCircleSlashRed,
  StyledIconUploadCloud,
  StyledCancel,
  StyledDismiss,
} from './JobStatusActive.styled';

interface JobStatusActiveProps {
  isJobActive: boolean;
  loading: boolean;
  setActiveCallback: (active: boolean) => void;
  setModalOpen: (status: boolean) => void;
  hasCandidates: boolean;
}

const JobStatusActive: React.FC<JobStatusActiveProps> = ({
  isJobActive,
  setActiveCallback,
  setModalOpen,
  hasCandidates,
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

  const { popperOpen, togglePopper, setPopperStatus } = usePopper('JobStatusActive');

  const onSave = () => {
    setActiveCallback(!isJobActive);
    setPopperStatus(false);
  };

  const handleDismissAndText = () => {
    setPopperStatus(false);
    setModalOpen(true);
  };

  return (
    <>
      <StyledShowStatus onClick={togglePopper} ref={setReferenceElement}>
        {isJobActive ? <StyledIconCircleSlashRed /> : <StyledIconUploadCloud />}
        <StyledText>{isJobActive ? 'Dismiss' : 'Activate'}</StyledText>
        <HeaderPopper referenceElement={referenceElement} show={popperOpen}>
          <StyledPopperContent>
            <div>{`Are you sure you want to ${isJobActive ? 'dismiss' : 'activate'} this job?`}</div>
            <div>
              <StyledCancel big onClick={() => setPopperStatus(false)} text={'Cancel'} />
              <StyledDismiss dark big onClick={() => onSave()} text={`Yes, ${isJobActive ? 'dismiss' : 'activate'}`} />

              {hasCandidates && isJobActive && (
                <StyledDismissAndText dark big onClick={handleDismissAndText} text={'Yes, dismiss and message'} />
              )}
            </div>
          </StyledPopperContent>
        </HeaderPopper>
      </StyledShowStatus>
    </>
  );
};

export default JobStatusActive;
