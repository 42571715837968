import React, { useEffect } from 'react';
import TimezoneSelect from 'react-timezone-select';
import DateTimePicker from 'react-datetime-picker';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';

import { ErrorMessage as FormErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';

import CancelButton from 'elitegrad-common/src/components/SecondaryButton';
import { EGLogo, Calendar as CalendarIcon } from 'elitegrad-common/src/assets/vectors';
import EventType from 'elitegrad-common/src/types/Event';
import FormInput from 'elitegrad-common/src/components/FormInput';
import { HookTextInput } from 'elitegrad-common/src/components/TextInput';
import { HookTextAreaInput } from 'elitegrad-common/src/components/TextAreaInput';
import { HookCheckbox } from 'elitegrad-common/src/components/Checkbox';
import PhotoUploader from 'elitegrad-common/src/components/PhotoUploader';
import ViewTitle from 'elitegrad-common/src/components/ViewTitle';

import {
  Spacing,
  ErrorMessage,
  SubmitButtonsContainer,
  SaveButton,
  UnlimitedCapacityContainer,
  UnlimitedCapacityLabel,
  DateTimeRangeContainer,
  DatePickerContainer,
} from 'components/events/EventForm/EventForm.styled';
import { schema } from 'components/events/EventForm/EventForm.validations';
import FormRadioButtonGroup from 'components/common/FormRadioButtonGroup';

import { ReactComponent as Close } from 'theme/assets/Close.svg';
import {
  FormWrapper,
  HorizontalSeparator,
  ModalContainer,
  TitleCloseIconWrapper,
  ModalWrapper,
} from './EventFormModal.styled';

interface ModalProps {
  handleClose: () => void;
  onSubmit: (data) => void;
  headTitle?: string;
  defaultValues?: EventType | null;
}

const setDefaultValues = (defaultValues?: EventType | null) => {
  return {
    title: defaultValues?.title || '',
    image: defaultValues?.image || EGLogo,
    description: defaultValues?.description || '',
    capacity: defaultValues?.capacity || 0,
    isUnlimitedCapacity: defaultValues?.capacity === 0,
    eventType: defaultValues?.eventType || 'Online',
    timezone: defaultValues?.timezone || '',
    startDate: defaultValues?.startDate ? new Date(defaultValues?.startDate) : new Date(),
    endDate: defaultValues?.endDate ? new Date(defaultValues?.endDate) : new Date(),
    link: defaultValues?.link || '',
    address: defaultValues?.address || '',
  };
};

const Modal: React.FC<ModalProps> = ({ onSubmit, handleClose, headTitle = 'New Event', defaultValues }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: setDefaultValues(defaultValues),
  });

  register('image');
  const { isUnlimitedCapacity, image, eventType } = watch(['isUnlimitedCapacity', 'image', 'eventType']);

  useEffect(() => {
    if (isUnlimitedCapacity) setValue('capacity', 0);
  }, [isUnlimitedCapacity, setValue]);

  return (
    <ModalWrapper>
      <ModalContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormWrapper>
            <TitleCloseIconWrapper>
              <ViewTitle> {headTitle} </ViewTitle>
              <Close onClick={handleClose} />
            </TitleCloseIconWrapper>
            <HorizontalSeparator />
            <Spacing />

            <FormInput label="">
              <PhotoUploader logo={image || EGLogo} onChange={(image) => setValue('image', image)} />
            </FormInput>
            <Spacing />

            <FormInput label="Event title">
              <HookTextInput register={register} name="title" />
              <FormErrorMessage errors={errors} as={ErrorMessage} name="title" />
            </FormInput>
            <Spacing />

            <FormInput label="Description">
              <HookTextAreaInput register={register} name="description" />
              <FormErrorMessage errors={errors} as={ErrorMessage} name="description" />
            </FormInput>
            <Spacing />

            <UnlimitedCapacityContainer>
              <HookCheckbox name="isUnlimitedCapacity" register={register} />
              <UnlimitedCapacityLabel>Is capacity unlimited?</UnlimitedCapacityLabel>
            </UnlimitedCapacityContainer>

            {!isUnlimitedCapacity && (
              <FormInput label="Capacity">
                <HookTextInput type="number" register={register} name="capacity" />
                <FormErrorMessage errors={errors} as={ErrorMessage} name="capacity" />
              </FormInput>
            )}
            <Spacing />

            <FormRadioButtonGroup
              options={['Online', 'In Person']}
              register={register}
              name="eventType"
              checkedValue={eventType}
            />
            <FormErrorMessage errors={errors} as={ErrorMessage} name="eventType" />
            <Spacing />

            {eventType === 'Online' ? (
              <FormInput label="Link to the meeting">
                <HookTextInput key="link" name="link" register={register} />
                <FormErrorMessage errors={errors} as={ErrorMessage} name="link" />
              </FormInput>
            ) : (
              eventType === 'In Person' && (
                <FormInput label="Address">
                  <HookTextInput key="address" name="address" register={register} />
                  <FormErrorMessage errors={errors} as={ErrorMessage} name="address" />
                </FormInput>
              )
            )}
            <Spacing />

            <FormInput label="Timezone">
              <Controller
                name="timezone"
                control={control}
                defaultValue={moment(defaultValues?.timezone)}
                render={({ onChange, value }) => (
                  <TimezoneSelect onChange={({ value }) => onChange(value)} value={value} />
                )}
              />
              <FormErrorMessage errors={errors} as={ErrorMessage} name="timezone" />
            </FormInput>
            <Spacing />

            <DateTimeRangeContainer>
              <DatePickerContainer>
                <FormInput label="Start Date">
                  <Controller
                    name="startDate"
                    control={control}
                    defaultValue={moment(defaultValues?.startDate).toDate()}
                    render={({ value, onChange }) => (
                      <DateTimePicker
                        value={moment(value).toDate()}
                        onChange={(value) => onChange(value)}
                        calendarIcon={<CalendarIcon />}
                      />
                    )}
                  />
                </FormInput>
              </DatePickerContainer>

              <DatePickerContainer>
                <FormInput label="End Date">
                  <Controller
                    name="endDate"
                    control={control}
                    defaultValue={moment(defaultValues?.endDate).toDate()}
                    render={({ value, onChange }) => (
                      <DateTimePicker
                        value={moment(value).toDate()}
                        onChange={(value) => onChange(value)}
                        calendarIcon={<CalendarIcon />}
                      />
                    )}
                  />
                </FormInput>
              </DatePickerContainer>
            </DateTimeRangeContainer>

            <FormErrorMessage errors={errors} as={ErrorMessage} name="startDate" />

            <FormErrorMessage errors={errors} as={ErrorMessage} name="endDate" />

            <Spacing />
          </FormWrapper>

          <SubmitButtonsContainer className={'sticky-buttons'}>
            <CancelButton big text="Cancel" onClick={handleClose} />
            <SaveButton big submit text="Save" />
          </SubmitButtonsContainer>
        </form>
      </ModalContainer>
    </ModalWrapper>
  );
};

export default Modal;
