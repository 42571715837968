import React from 'react';

import Modal from '../../../components/Modal';
import ChatController from './ChatController';

export interface ChatModalProps {
  candidateId: string;
  setModalOpen: (status: boolean) => void;
  generateCandidatePath: (userId: string) => string;
}

const ChatModal: React.FC<ChatModalProps> = ({ setModalOpen, candidateId, generateCandidatePath }) => {
  return (
    <Modal setModalOpen={setModalOpen}>
      <ChatController candidateId={candidateId} generateCandidatePath={generateCandidatePath} />
    </Modal>
  );
};

export default ChatModal;
