import styled from 'styled-components';
import colors from 'elitegrad-common/src/theme/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 1rem;

  font-size: 1.5rem;
  line-height: 1.8rem;

  color: ${colors.black};
`;

export const Section = styled.div`
  margin: 2rem;
`;

export const ColumnsSection = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ColumnImg = styled.div`
  margin-right: 4rem;
`;

export const Column = styled.div`
  width: 20%;
  margin-right: 4rem;

  & > div {
    margin-bottom: 2rem;
  }
`;

export const ColumnTitle = styled.div`
  max-width: 80%;
  margin-right: 4rem;

  & > div {
    margin-bottom: 2rem;
  }
`;

export const Spacing = styled.div`
  margin: 2rem 0;
`;

export const Label = styled.div`
  margin-bottom: 1rem;

  font-size: 1.5rem;
  font-weight: 600;
`;

export const Title = styled.div`
  font-size: 3.6rem;
  line-height: 4.2rem;
`;

export const DescriptionContainer = styled.div`
  max-width: 100%;
`;

export const CardHeaderIcons = styled.div`
  div {
    width: fit-content;
    padding: 1px 4px;
    border-radius: 5px;
    margin-bottom: 1.5rem;
    height: 2rem;
    background-color: ${colors.red};
    color: white;
  }

  [alt='capacity'] {
    margin-right: 0.3rem;
  }
`;
