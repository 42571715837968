import gql from 'graphql-tag';

import { JobParts } from './fragments.gql';

export default gql`
  mutation UpdateJobsStatusPublic($jobId: String!, $public: Boolean!) {
    update_recruit_job_posting(where: { job_posting_id: { _eq: $jobId } }, _set: { public: $public }) {
      returning {
        ...JobParts
      }
    }
  }
  ${JobParts}
`;
