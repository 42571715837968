import styled from 'styled-components';

export const StyledItemName = styled.span`
  font-weight: 900;
  font-size: 1.3rem;
  line-height: 1.6rem;
`;

export const StyledItemValue = styled.span`
  margin-left: 0.6rem;

  font-size: 1.3rem;
  line-height: 1.6rem;
`;
