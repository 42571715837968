import { useLazyQuery } from '@apollo/client';
import {
  GetProfileImagesByUserIds,
  GetProfileImagesByUserIdsVariables,
} from 'elitegrad-common/src/generated/GetProfileImagesByUserIds';
import GET_PROFILE_IMAGES_BY_USER_IDS from 'elitegrad-common/src/queries/getProfileImagesByUserIds.gql';
import { GetJobCVs, GetJobCVsVariables } from 'elitegrad-common/src/generated/GetJobCVs';
import GET_JOB_CVS from 'elitegrad-common/src/queries/getJobCVs.gql';
import { GetCandidates, GetCandidatesVariables } from 'elitegrad-common/src/generated/GetCandidates';
import GET_CANDIDATES from 'elitegrad-common/src/queries/getCandidates.gql';

export const useProfileImageLazyQuery = () => {
  return useLazyQuery<GetProfileImagesByUserIds, GetProfileImagesByUserIdsVariables>(GET_PROFILE_IMAGES_BY_USER_IDS);
};

export const useCvsLazyQuery = () => {
  return useLazyQuery<GetJobCVs, GetJobCVsVariables>(GET_JOB_CVS);
};

export const useCandidatesLazyQuery = (onComplete: (data: GetCandidates) => void) => {
  return useLazyQuery<GetCandidates, GetCandidatesVariables>(GET_CANDIDATES, {
    onCompleted: (data) => {
      if (onComplete) onComplete(data);
    },
  });
};
