import React from 'react';
import { dateToDateString, dateToTimeString } from 'utils/dates';

import {
  Section,
  ColumnsSection,
  ColumnImg,
  Column,
  Title,
  CardHeaderIcons,
  Spacing,
  ColumnTitle,
} from './EventDetail.styled';
import Candidates from 'elitegrad-common/src/assets/vectors/CandidatesWhite.svg';
import EGLogoGrayBackground from 'elitegrad-common/src/assets/vectors/EGLogoGrayBackground.svg';

interface EventDetailMainInfoProps {
  title: string;
  company: string;
  image: string;
  startDate: Date;
  endDate: Date;
  capacity: number;
  isVirtual: boolean;
}

const EventDetailMainInfo: React.FC<EventDetailMainInfoProps> = ({
  title,
  company,
  image,
  startDate,
  endDate,
  capacity,
  isVirtual,
}) => {
  return (
    <Section>
      <ColumnsSection>
        <ColumnImg>
          <img src={image || EGLogoGrayBackground} width="200px" height="200px" alt="event" />
        </ColumnImg>
        <ColumnTitle>
          <Title>{title}</Title>
          <strong>{company}</strong>
          <Spacing />
          <div>
            start: {dateToDateString(startDate)} {dateToTimeString(startDate)}
          </div>
          <div>
            end: {dateToDateString(endDate)} {dateToTimeString(endDate)}
          </div>
        </ColumnTitle>
        <Column>
          <CardHeaderIcons>
            {capacity > 0 && (
              <div>
                <img src={Candidates} width="12px" height="12px" alt="capacity" />
                {capacity}
              </div>
            )}
            {isVirtual && <div>VIRTUAL</div>}
          </CardHeaderIcons>
        </Column>
      </ColumnsSection>
    </Section>
  );
};

export default EventDetailMainInfo;
