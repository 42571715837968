import gql from 'graphql-tag';

import { JobParts } from './fragments.gql';

export default gql`
  mutation UpdateJob(
    $job_posting_id: String!
    $description: String!
    $position_title: String!
    $job_type: String!
    $deadline: date
    $region: String!
  ) {
    update_recruit_job_posting(
      where: { job_posting_id: { _eq: $job_posting_id } }
      _set: {
        description: $description
        position_title: $position_title
        job_type: $job_type
        deadline: $deadline
        region: $region
      }
    ) {
      returning {
        ...JobParts
      }
    }
  }
  ${JobParts}
`;
