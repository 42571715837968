import React from 'react';
import { Section, Label, Spacing, DescriptionContainer } from './EventDetail.styled';

interface EventDetailDescriptionProps {
  description: string;
  address: string;
  link: string;
}

const EventDetailDescription: React.FC<EventDetailDescriptionProps> = ({ description, address, link }) => {
  return (
    <Section>
      <Label>Description:</Label>
      <Spacing />
      <DescriptionContainer>
        <p>{description}</p>
      </DescriptionContainer>
      <Spacing />
      <Label>{address ? 'Address:' : 'Link to event:'}</Label>
      <Spacing />
      <p>{address ? address : link}</p>
    </Section>
  );
};

export default EventDetailDescription;
