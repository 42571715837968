import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import { colors } from 'elitegrad-common/src/theme';
import styled from 'styled-components';

export const HeaderButton = styled(PrimaryButton)`
  margin-left: 2rem;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: 2rem 3rem 1rem 2rem;

  font-family: 'RobotoMedium';
  font-size: 1rem;
`;

export const EventListContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1rem;

  & > div {
    margin-left: 2rem;
  }
`;

export const Container = styled.div`
  padding-left: 2rem;
  padding-top: 3rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 2rem;
  margin-top: 3rem;
`;

export const EventsCount = styled.div`
  font-family: 'RobotoMedium';
  margin-right: 1rem;
  font-size: 1.2rem;
  line-height: 1.2rem;

  color: ${colors.gray_dark};

  padding: 2rem 0;
`;

export const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: ${colors.gray_light_l2};
  white-space: pre-line;
  text-align: center;
`;

export const PlaceholderIcon = styled.img`
  width: 10rem;

  margin: 2rem 0;
`;

export const PlaceholderContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 1.6rem;
  color: ${colors.gray_light_l2};
  margin-top: 10rem;
`;
