import React, { useState } from 'react';

import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import SecondaryButton from 'elitegrad-common/src/components/SecondaryButton';
import HeaderPopper from 'elitegrad-common/src/components/HeaderPopper';

import { StyledPopperContent, StyledText, StyledShowStatus, StyledEyeOff, StyledEyeOn } from './JobStatusHidden.styled';

interface JobStatusHiddenProps {
  hidden: boolean;
  popperOpen: boolean;
  togglePopper: () => void;
  onSave: () => void;
  setPopperStatus: (value: boolean) => void;
}

const JobStatusHidden: React.FC<JobStatusHiddenProps> = ({
  togglePopper,
  hidden,
  popperOpen,
  onSave,
  setPopperStatus,
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

  return (
    <>
      <StyledShowStatus onClick={togglePopper} ref={setReferenceElement}>
        {hidden ? <StyledEyeOn /> : <StyledEyeOff />}
        <StyledText>{hidden ? 'Publish' : 'Hide'}</StyledText>
      </StyledShowStatus>
      <HeaderPopper referenceElement={referenceElement} show={popperOpen}>
        <StyledPopperContent>
          <div>{`Are you sure you want to ${hidden ? 'publish' : 'hide'} this Job?`}</div>
          <div>
            <SecondaryButton big onClick={() => setPopperStatus(false)} text={'No, Cancel'} />
            <PrimaryButton dark big onClick={onSave} text={`Yes, ${hidden ? 'Publish' : 'Hide'}`} />
          </div>
        </StyledPopperContent>
      </HeaderPopper>
    </>
  );
};

export default JobStatusHidden;
