import gql from 'graphql-tag';

export default gql`
  mutation DeleteEvent($eventID: String!) {
    delete_recruit_event(where: { event_id: { _eq: $eventID } }) {
      returning {
        event_id
      }
    }
  }
`;
