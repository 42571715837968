import styled from 'styled-components';

import { colors } from 'elitegrad-common/src/theme';

export const CardContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;

  width: 28.5rem;
  height: 21.8rem;

  padding: 0.8rem 1.2rem;

  border: ${({ isSelected }) => (isSelected ? `2px solid ${colors.red}` : `1px solid ${colors.gray_light_l3}`)};
  border-radius: 12px;

  cursor: pointer;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CardVirualBadge = styled.div<{ isVirtual?: boolean }>`
  display: flex;
  max-width: 5.5rem;
  max-height: 1rem;
  font-size: 0.9rem;
  font-family: 'RobotoBlack';
  justify-content: center;
  align-items: center;
  padding: 1px 4px;
  border-radius: 2px;
  width: 100%;
  height: 2rem;
  background-color: ${({ isVirtual }) => (isVirtual ? colors.green400 : colors.green600)};
  color: white;
`;

export const DateContainer = styled.span`
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  font-family: 'RobotoMedium';
  margin-bottom: 0.5rem;
  color: ${colors.gray600};
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const HiddenTag = styled.div`
  font-size: 1rem;
  color: ${colors.red};
`;

export const ActiveTag = styled.div`
  font-size: 1rem;
  color: ${colors.green_light};
`;

export const Separator = styled.div`
  margin: 0.4rem 0;
`;

export const Title = styled.h1`
  font-size: 1.3rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  text-decoration: inherit;
  color: inherit;

  margin-bottom: 0.5rem;
`;

export const EventImg = styled.img`
  height: 5rem;
  width: 5rem;
  border-radius: 100%;
  margin: 1rem 0;
`;

export const Address = styled.div`
  color: ${colors.gray600};
  margin-bottom: 1rem;
  font-size: 1.1rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Capacity = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.gray_2};
  font-size: 1.1rem;
  margin-top: 1rem;

  img {
    margin-right: 0.5rem;
  }
`;
