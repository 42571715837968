import gql from 'graphql-tag';

import { JobParts } from './fragments.gql';

export default gql`
  query GetJobs($offset: Int = 0, $limit: Int = 12) {
    recruit_job_posting_aggregate {
      aggregate {
        count
      }
    }
    recruit_job_posting(offset: $offset, limit: $limit) {
      ...JobParts
    }
  }
  ${JobParts}
`;
