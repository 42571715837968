import styled from 'styled-components';
import Select from 'elitegrad-common/src/components/Select';

export const Container = styled.div`
  margin-left: 2rem;
`;

export const CandidatesList = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledSelect = styled(Select)`
  width: 20rem;
  font-size: 1.4rem;
  color: black;
`;

export const RaisedHandsTitle = styled.h2`
  padding: 2rem 0 0 2rem;
`;
