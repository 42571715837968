import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import styled from 'styled-components';

const EventStatusCreate = styled(PrimaryButton)`
  margin-left: auto;
  border-radius: 6px;
  font-weight: 400;
  min-height: 3.6rem;
`;

export default EventStatusCreate;
