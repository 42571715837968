import moment from 'moment';
import React from 'react';

import {
  Content,
  Deadline,
  Description,
  InfoSection,
  Label,
  LeftSection,
  Location,
  RightSection,
  StyledInternshipTag,
  Title,
} from './JobDetail.styled';

export interface JobProps {
  title: string;
  region: string;
  deadline: moment.Moment;
  description: string;
  isInternship: boolean;
}

const JobDetail: React.FC<JobProps> = ({ title, description, region, deadline, isInternship }) => {
  return (
    <Content>
      <LeftSection>
        <Title>{title}</Title>
        {isInternship && <StyledInternshipTag />}
        <Description>{description}</Description>
      </LeftSection>
      <RightSection>
        {deadline && (
          <InfoSection>
            <Label>Deadline:</Label>
            <Deadline>{moment(deadline).format('LL')}</Deadline>
          </InfoSection>
        )}
        {region && (
          <InfoSection>
            <Label>Location:</Label>
            <Location>{region}</Location>
          </InfoSection>
        )}
      </RightSection>
    </Content>
  );
};

export default JobDetail;
