import React from 'react';
import CalendarIcon from 'elitegrad-common/src/assets/vectors/Calendar.svg';
import { UnionPeople } from 'elitegrad-common/src/assets/icons';

import {
  CardContainer,
  CardHeader,
  CardVirualBadge,
  DateContainer,
  HiddenTag,
  ActiveTag,
  TopSection,
  Separator,
  Title,
  EventImg,
  Address,
  Capacity,
} from './EventCard.styled';

interface EventCardProps {
  title: string;
  picture: string;
  formatedDate: string;
  isVirtual: boolean;
  capacity: number;
  address?: string | null;
  isHidden: boolean;
  isSelected: boolean;
  onSelectEvent: () => void;
}

const EventCard: React.FC<EventCardProps> = ({
  title,
  picture,
  formatedDate,
  isVirtual,
  capacity,
  address,
  isHidden,
  isSelected,
  onSelectEvent,
}) => {
  return (
    <CardContainer isSelected={isSelected} onClick={() => onSelectEvent()}>
      <CardHeader>
        {isHidden ? <HiddenTag>HIDDEN</HiddenTag> : <ActiveTag>ACTIVE</ActiveTag>}
        <Separator />
        <TopSection>
          <EventImg width="90px" height="90px" src={picture || CalendarIcon} alt={title} />
          <CardVirualBadge isVirtual={isVirtual}>{isVirtual ? 'ONLINE' : 'IN PERSON'}</CardVirualBadge>
        </TopSection>
      </CardHeader>
      <Title>{title}</Title>
      <DateContainer>{formatedDate}</DateContainer>
      <Address>{address}</Address>
      {capacity > 0 && (
        <Capacity>
          <img src={UnionPeople} alt="people" />
          {capacity}
        </Capacity>
      )}
    </CardContainer>
  );
};

export default EventCard;
