import { colors } from 'elitegrad-common/src/theme';
import styled from 'styled-components';
import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 50rem;
  margin: 0 auto;
`;

export const SaveButton = styled(PrimaryButton)`
  margin-left: 1rem;
  background-color: ${colors.light_black};
  border-radius: 5px;
  min-height: 4.2rem;
  min-width: 9.5rem;
`;

export const SubmitButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  bottom: 0;
  background-color: ${colors.gray_light_l10};
  padding: 1.5rem;
`;

export const Spacing = styled.div`
  padding: 1rem 0;
`;

export const UnlimitedCapacityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1rem;
`;

export const UnlimitedCapacityLabel = styled.div`
  padding: 1rem;
  font-size: 1.5rem;

  line-height: 1.8rem;
  color: ${colors.black};
`;

export const DateTimeRangeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  justify-content: space-between;

  padding: 2rem 0;
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 1.4rem;
  margin-top: 0.5rem;

  :before {
    display: inline;
    content: '⚠ ';
  }
`;

export const DatePickerContainer = styled.div`
  padding-bottom: 6px;
  .react-datepicker__input-container input {
    width: 100%;
    height: 3.4rem;

    border: 1px solid ${colors.gray_l1};
    border-radius: 4px;
    background-color: transparent;

    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.6rem;
    color: ${colors.black};
    padding-left: 0.5rem;

    :focus {
      outline: none;
    }

    ::placeholder {
      color: ${colors.gray_l1};
    }
  }

  .react-datepicker {
    font-size: 1.3rem;
  }

  .react-datepicker__month .react-datepicker__month-text {
    width: 5rem;
  }

  .react-datepicker__calendar-icon {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .react-datepicker__close-icon {
    top: 2px;
    right: 27px;

    ::after {
      background-color: transparent;
      color: ${colors.gray_l1};
      font-size: 21px;
    }
  }
`;
