import React from 'react';
import { useHistory } from 'react-router-dom';
import { Paths } from 'routes';

import { useAuth } from 'elitegrad-common/src/utils/react-auth';
import Spinner from 'elitegrad-common/src/components/Spinner';
import EventType from 'elitegrad-common/src/types/Event';

import NewEvent from './NewEvent';
import { useNewEventMutation, useNewEventQuery } from './NewEvent.hook';
import { dateToTimestamp } from 'utils/dates';

const NewEventController: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { data: companyData, loading } = useNewEventQuery(user?.sub || '');
  const insertEvent = useNewEventMutation();

  const submit = async (data: EventType) => {
    const companyID = companyData?.recruit_company[0]?.account_id;
    if (!companyID) return;

    await insertEvent({
      variables: {
        ...data,
        capacity: data.capacity || 0,
        userID: user?.sub || '',
        companyID,
        showEvent: true,
        startDate: dateToTimestamp(data.startDate),
        endDate: dateToTimestamp(data.endDate),
      },
    });

    history.push(Paths.events);
  };

  const onCancel = () => {
    history.goBack();
  };

  return loading ? <Spinner /> : <NewEvent submit={submit} onCancel={onCancel} />;
};

export default NewEventController;
