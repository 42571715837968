import styled from 'styled-components';
import colors from 'elitegrad-common/src/theme/colors';

export const Container = styled.div`
  margin: 0 3.1rem 0;
`;

export const ManagerTag = styled.span`
  display: inline;
  width: 8rem;

  padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  border-radius: 3px;

  background-color: ${colors.red};

  font-size: 1.1rem;
  font-weight: 900;
  line-height: 1.8rem;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.white};
`;
