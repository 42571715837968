import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetJobDetailQuery, useGetJobDismissRecipientsByIdLazyQuery } from 'elitegrad-common/src/generated/graphql';

import JobDetail from './JobDetail';

import moment from 'moment';
import { asJobType } from 'elitegrad-common/src/transformers/jobs';
import { asJobRequirement, asRecipient } from './JobDetail.utils';
import JobRequirement from 'elitegrad-common/src/types/JobRequirement';
import NotFoundView from 'elitegrad-common/src/components/NotFoundView';
import Page from '../../Page';
import Header from 'elitegrad-common/src/components/Header';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';
import JobHeader from '../JobHeader';

import { MessageNewModal } from 'elitegrad-common/src/components/messaging';
import JobCandidatesDashboardController from './JobCandidatesDashboard';
import { useHistory } from 'react-router-dom';
import { Paths } from 'routes';

const JobDetailController: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [modalOpenJobDismiss, setModalOpenJobDismiss] = useState(false);
  const history = useHistory();

  const { data: jobData, loading: loadingJob } = useGetJobDetailQuery({
    fetchPolicy: 'network-only',
    skip: !id,
    variables: {
      id: id || '',
    },
  });

  const allCandidatesIds = jobData?.recruit_job_posting[0].raise_hands?.map((rh) => rh.user_id) || [];

  const [fetchRecipients, { data: dataRecipients, loading: loadingRecipients }] =
    useGetJobDismissRecipientsByIdLazyQuery({
      variables: { ids: allCandidatesIds },
    });
  let recipients = dataRecipients?.grad_profile.map(asRecipient) || [];

  const job = jobData?.recruit_job_posting[0] || null;
  const requirements = jobData?.recruit_job_posting[0].job_requirements
    .map(asJobRequirement)
    .filter((v) => v !== null) as JobRequirement[];

  useEffect(() => {
    if (modalOpenJobDismiss) fetchRecipients();
  }, [modalOpenJobDismiss, fetchRecipients]);

  if (loadingJob) {
    return (
      <Page>
        <Header backLink={true} />
        <SpinnerIsoType text="Loading" />
      </Page>
    );
  }

  if (!job) return <NotFoundView />;
  const {
    active,
    public: isPublic,
    job_posting_id: jobId,
    position_title,
    deadline,
    description,
    region,
    job_type,
  } = job;

  const onCreateJob = () => history.push(Paths.newJob);

  const handleDeleteRecipient = (recipientId: string) => {
    recipients = recipients.filter((recipient) => recipient.id === recipientId);
  };

  const header = (
    <JobHeader
      setModalOpen={setModalOpenJobDismiss}
      isActive={active}
      isHidden={!isPublic}
      jobId={jobId}
      hasCandidates={allCandidatesIds.length > 0}
      onCreateJob={onCreateJob}
    />
  );
  const isInternship = Boolean(asJobType(job_type)?.match(/internship/i)) || false;
  return (
    <Page headerComponent={header}>
      <JobDetail
        isInternship={isInternship}
        title={position_title || ''}
        description={description || ''}
        region={region || ''}
        deadline={moment(deadline)}
      />
      {modalOpenJobDismiss && (
        <MessageNewModal
          jobId={jobId}
          loading={loadingRecipients}
          setModalOpen={setModalOpenJobDismiss}
          recipients={recipients}
          handleDeleteRecipient={handleDeleteRecipient}
        />
      )}
      <JobCandidatesDashboardController candidatesUserIds={allCandidatesIds} jobRequirements={requirements} />
    </Page>
  );
};

export default JobDetailController;
