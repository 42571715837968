import React from 'react';
import styled from 'styled-components';

import { Group as GroupIcon, Edit as EditIcon } from '../../assets/vectors';
import { colors } from '../../theme';
import Group from '../../types/Group';

const StyledCard = styled.div`
  display: flex;
  align-items: center;

  padding-left: 1.8rem;
  padding-right: 1.8rem;

  color: ${colors.neo_gray};
`;

const StyledGroupIcon = styled(GroupIcon)`
  width: 1.4rem;
  height: 1.4rem;

  & * {
    stroke: ${colors.black};
  }
`;

const StyledEditIcon = styled(EditIcon)`
  width: 1.4rem;
  height: 1.4rem;

  & * {
    stroke: ${colors.black};
  }
`;

const StyledText = styled.div`
  flex-grow: 1;

  margin: 0 1rem;

  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: ${colors.black};
`;

const StyledGroup = styled.span`
  color: ${colors.red};
`;

interface CandidateCardGroupProps {
  group: Group;
}

const CandidateCardGroup: React.FC<CandidateCardGroupProps> = ({ group }) => {
  return (
    <StyledCard>
      <StyledGroupIcon />
      <StyledText>
        Last added in <StyledGroup>{group.name}</StyledGroup>
      </StyledText>
      <StyledEditIcon />
    </StyledCard>
  );
};

export default CandidateCardGroup;
