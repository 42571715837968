import {
  GetJobDetailRequirementPartsFragment,
  GetJobDetailRequirementValuePartsFragment,
  GetJobDismissRecipientsByIdPartsFragment,
} from 'elitegrad-common/src/generated/graphql';
import { FilterOption } from 'elitegrad-common/src/types/FilterValueType';
import JobRequirement from 'elitegrad-common/src/types/JobRequirement';
import JobOpType from 'elitegrad-common/src/types/JobOpType';
import Recipient from 'elitegrad-common/src/types/Recipient';

export const asJobRequirement = ({
  requirement_type,
  requirement_name,
  job_requirement_id,
  min_range_value,
  max_range_value,
  boolean_value,
  values,
}: GetJobDetailRequirementPartsFragment): JobRequirement | null => {
  switch (requirement_type) {
    case JobOpType.OPTIONS:
      const filterOptions = (values || []).map(asFilterOptionNew).filter((v) => v !== null) as FilterOption[];
      return {
        name: requirement_name,
        requirementType: JobOpType.OPTIONS,
        value: filterOptions,
        id: job_requirement_id,
      };
    case JobOpType.RANGE:
      return {
        name: requirement_name,
        requirementType: JobOpType.RANGE,
        value: [min_range_value as number, max_range_value as number],
        id: job_requirement_id,
      };
    case JobOpType.BOOLEAN:
      return {
        name: requirement_name,
        requirementType: JobOpType.BOOLEAN,
        value: boolean_value as boolean,
        id: job_requirement_id,
      };
    case JobOpType.RANGE_BOOLEAN:
      return {
        name: requirement_name,
        requirementType: JobOpType.RANGE_BOOLEAN,
        value: {
          bool: boolean_value as boolean,
          range: [min_range_value as number, max_range_value as number],
        },
        id: job_requirement_id,
      };
  }
  console.warn(`Unknown JobRequirementType: ${requirement_type}`); // eslint-disable-line no-console
  return null;
};

export const asFilterOptionNew = ({
  value,
  label,
  value_id,
}: GetJobDetailRequirementValuePartsFragment): FilterOption | null => {
  if (value === null || value_id === null || label === null) return null;
  return {
    filterValue: value || '',
    displayValue: label || '',
    id: value_id || '',
  };
};

export const asRecipient = (recipient: GetJobDismissRecipientsByIdPartsFragment): Recipient => {
  return {
    firstname: recipient.firstname,
    middlename: '',
    lastname: '',
    fullname: recipient.fullname,
    title: '',
    photo: '',
    id: recipient.user_id,
  };
};
