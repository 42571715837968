import React, { Ref } from 'react';
import styled from 'styled-components/macro';

import colors from '../theme/colors';

const RADIO_BUTTON_SIZE = '1.6rem';
const RADIO_DOT_SIZE = '0.8rem';

const StyledRadioButton = styled.div`
  position: relative;

  display: inline-block;

  height: ${RADIO_BUTTON_SIZE};
`;

const StyledRadioCircle = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: ${RADIO_BUTTON_SIZE};
  height: ${RADIO_BUTTON_SIZE};

  border-radius: 50%;
  border-width: 2px;
  border-color: ${colors.black};
  border-style: solid;

  background-color: transparent;

  vertical-align: top;

  cursor: pointer;
`;

const StyledRadioDot = styled.span`
  display: inline-block;

  width: calc(${RADIO_BUTTON_SIZE} - ${RADIO_DOT_SIZE});
  height: calc(${RADIO_BUTTON_SIZE} - ${RADIO_DOT_SIZE});

  border-radius: 50%;
  border-width: 0px;

  background-color: ${colors.black};
`;

export interface RadioButtonProps {
  checked: boolean;
  onClick: () => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({ checked, onClick }) => {
  return (
    <StyledRadioButton onClick={onClick}>
      <StyledRadioCircle>{checked && <StyledRadioDot />}</StyledRadioCircle>
    </StyledRadioButton>
  );
};

interface HookRadioButtonProps {
  name: string;
  value: string;
  register: Ref<HTMLInputElement>;
  checked?: boolean;
}

const StyledCustomHookRadioButtonContainer = styled.span`
  position: relative;
  margin-left: 0.7rem;

  display: inline-block;

  height: ${RADIO_BUTTON_SIZE};
`;

const StyledCustomHookRadioButton = styled.input`
  display: none;
`;

const HookRadioButtonDot = styled.span`
  display: inline-block;

  width: calc(${RADIO_BUTTON_SIZE} - ${RADIO_DOT_SIZE});
  height: calc(${RADIO_BUTTON_SIZE} - ${RADIO_DOT_SIZE});

  border-radius: 50%;
  border-width: 0px;

  background-color: ${colors.black};
`;

export const HookRadioButton: React.FC<HookRadioButtonProps> = (props) => {
  const { name, register, value, checked } = props;
  return (
    <StyledCustomHookRadioButtonContainer>
      <StyledCustomHookRadioButton name={name} value={value} ref={register} type="radio" />
      <StyledRadioCircle>{checked && <HookRadioButtonDot />}</StyledRadioCircle>
    </StyledCustomHookRadioButtonContainer>
  );
};

export default RadioButton;
