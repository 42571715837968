import React from 'react';
import { useHistory } from 'react-router-dom';
import StatusDelete from 'components/common/PostStatusDelete';
import { useEventStatusDeleteMutation } from './EventStatusDelete.hook';
import { Paths } from 'routes';

interface EventStatusDeleteProps {
  eventID: string;
}

const EventeStatusDelete: React.FC<EventStatusDeleteProps> = ({ eventID }) => {
  const [deleteEvent, { loading }] = useEventStatusDeleteMutation();
  const history = useHistory();

  const onDelete = async () => {
    await deleteEvent({
      variables: {
        eventID,
      },
    });

    history.push(Paths.events);
  };

  return <StatusDelete loading={loading} onDelete={onDelete} />;
};

export default EventeStatusDelete;
