import React, { useEffect } from 'react';

import { useAuth } from 'elitegrad-common/src/utils/react-auth';
import { useImpersonation } from 'elitegrad-common/src/utils/useImpersonation';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';

const AuthLogout: React.FC = () => {
  const { doLogout } = useAuth();
  const { isImpersonationRunning, removeImpersonationProps } = useImpersonation();

  useEffect(() => {
    if (isImpersonationRunning()) removeImpersonationProps();
    doLogout();
  }, [doLogout]); //eslint-disable-line

  return <SpinnerIsoType fullPage text="Logout" />;
};

export default AuthLogout;
