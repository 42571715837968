import React from 'react';
import EventDetailMainInfo from './EventDetailMainInfo';
import EventDetailDescription from './EventDetailDescription';

export interface EventDetailProps {
  capacity: number;
  company: string;
  description: string;
  endDate: Date;
  isVirtual: boolean;
  image: string;
  startDate: Date;
  title: string;
  address: string;
  link: string;
}

const EventDetail: React.FC<EventDetailProps> = ({
  capacity,
  company,
  description,
  endDate,
  isVirtual,
  image,
  startDate,
  title,
  address,
  link,
}) => {
  return (
    <>
      <EventDetailMainInfo
        title={title}
        capacity={capacity}
        image={image}
        company={company}
        startDate={startDate}
        endDate={endDate}
        isVirtual={isVirtual}
      />
      <EventDetailDescription description={description} address={address} link={link} />
    </>
  );
};

export default EventDetail;
