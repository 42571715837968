import React, { useState } from 'react';

import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import SecondaryButton from 'elitegrad-common/src/components/SecondaryButton';
import Spinner from 'elitegrad-common/src/components/Spinner';
import HeaderPopper from 'elitegrad-common/src/components/HeaderPopper';
import { usePopper } from 'elitegrad-common/src/utils/usePopper';
import { StyledIcon, StyledText, StyledShowStatus, StyledPopperContent } from './JobStatusDelete.styled';

interface JobStatusDeleteProps {
  loading: boolean;
  onDelete: () => void;
}

const LoadingPopperContent: React.FC = () => {
  return (
    <div>
      <Spinner />
      <div>Deleting Job ...</div>
    </div>
  );
};

const PopperContent: React.FC<{ onDelete: () => void; setShow: (show: boolean) => void }> = ({ setShow, onDelete }) => {
  return (
    <>
      <div>Are you sure you want to delete this Job?</div>
      <div>
        <SecondaryButton big onClick={() => setShow(false)} text={'No, Cancel'} />
        <PrimaryButton dark big onClick={onDelete} text={'Yes, Delete'} />
      </div>
    </>
  );
};

const JobStatusDelete: React.FC<JobStatusDeleteProps> = ({ loading, onDelete }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

  const { popperOpen, togglePopper, setPopperStatus } = usePopper('JobStatusDelete');

  return (
    <>
      <StyledShowStatus onClick={togglePopper} ref={setReferenceElement}>
        <StyledIcon />
        <StyledText>Delete</StyledText>
      </StyledShowStatus>
      <HeaderPopper referenceElement={referenceElement} show={popperOpen}>
        <StyledPopperContent>
          {loading ? <LoadingPopperContent /> : <PopperContent setShow={setPopperStatus} onDelete={onDelete} />}
        </StyledPopperContent>
      </HeaderPopper>
    </>
  );
};

export default JobStatusDelete;
