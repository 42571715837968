import React, { Ref } from 'react';
import { HookRadioButton } from 'elitegrad-common/src/components/RadioButton';
import FormInput, { LabelPosition } from 'elitegrad-common/src/components/FormInput';

import { RadioButtonContainer, RadioButtonGroupOptions } from './FormRadioButtonGroup.styled';

interface RadioButtonGroupProps {
  name: string;
  checkedValue: string;
  options: string[];
  register: Ref<HTMLInputElement>;
}

const FormRadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  options,
  name,
  register,
  checkedValue,
}: RadioButtonGroupProps) => {
  return (
    <FormInput label="Type of event">
      <RadioButtonGroupOptions>
        {options.map((opt) => (
          <RadioButtonContainer key={opt}>
            <FormInput label={opt} labelPosition={LabelPosition.Right}>
              <HookRadioButton name={name} value={opt} register={register} checked={checkedValue === opt} />
            </FormInput>
          </RadioButtonContainer>
        ))}
      </RadioButtonGroupOptions>
    </FormInput>
  );
};

export default FormRadioButtonGroup;
