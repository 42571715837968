import moment from 'moment-timezone';

export const timestampToLocalDate = (date: string, timezone: string): Date => {
  return moment.tz(date, timezone).toDate();
};

export const dateToTimestamp = (date: Date): string => {
  return moment(date).format('YYYY-MM-DD hh:mm:ss a');
};

export const dateToDateString = (date: Date): string => {
  return moment(date).format('MM/DD/YYYY');
};

export const dateToTimeString = (date: Date): string => {
  return moment(date).format('hh:mm a');
};

export const dateToDateTimeString = (date: Date): string => moment(date).format('MMMM DD YYYY');

const getTimeZoneAbbreviation = (timeZone: string): string => {
  let timeZoneAbb = moment.tz(timeZone).zoneName();
  if (timeZoneAbb.startsWith('-') || timeZoneAbb.startsWith('+')) {
    timeZoneAbb = 'GMT' + timeZoneAbb;
  }
  return timeZoneAbb;
};

export const getTimezoneAbreviation = (timezone: string): string => {
  const getAbbreviation = getTimeZoneAbbreviation(timezone);
  return getAbbreviation.startsWith('+') ? `GMT${getAbbreviation}` : getAbbreviation;
};

export const getHourInCentralTime = (date: Date): string => {
  const convertedDate = new Date(date.toLocaleString('en-US'));
  let hours = convertedDate.getHours();
  const AmOrPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  let minutes = String(convertedDate.getMinutes());
  if (minutes === '0') {
    minutes = minutes + '0';
  }
  const finalTime = hours + ':' + minutes + ' ' + AmOrPm;

  return finalTime;
};

export const formatMonthYearWithComma = (date: Date): string => {
  return moment(date).format('MMMM YYYY');
};
