import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { asRegion } from 'elitegrad-common/src/transformers/regions';
import RegionPicker from './RegionPicker';

import { GetRegions } from 'elitegrad-common/src/generated/GetRegions';
import GET_REGIONS from 'elitegrad-common/src/queries/getRegions.gql';

export interface RegionPickerControllerProps {
  value: string | null; // The name of the region
  onChange?: (value: string | null) => void;
}

const RegionPickerController: React.FC<RegionPickerControllerProps> = ({ value, onChange = () => undefined }) => {
  const { data, loading } = useQuery<GetRegions>(GET_REGIONS);

  const regions = useMemo(() => {
    return data?.grad_interest_region_selection.map(asRegion) || [];
  }, [data]);

  return <RegionPicker value={value} regions={regions} loading={loading} onChange={onChange} />;
};

export default RegionPickerController;
