import React from 'react';

import styled from 'styled-components';

interface ErrorViewProps {
  message: string;
}

const ErrorScreen = styled.div`
  display: flex;
  height: 2rem;
  justify-content: center;
  align-content: center;

  text-align: center;
  font-size: 2rem;
  white-space: pre;
`;

const ErrorView: React.FC<ErrorViewProps> = ({ message }) => {
  return <ErrorScreen>{message}</ErrorScreen>;
};

export default ErrorView;
