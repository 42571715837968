import React from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { colors } from 'elitegrad-common/src/theme';

import FormInput from 'elitegrad-common/src/components/FormInput';
import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import TextAreaInput from 'elitegrad-common/src/components/TextAreaInput';
import TextInput from 'elitegrad-common/src/components/TextInput';
import SecondaryButton from 'elitegrad-common/src/components/SecondaryButton';
import Company from 'elitegrad-common/src/types/Company';
import PhotoUploader from 'elitegrad-common/src/components/PhotoUploader';

import SubIndustrySelector from 'elitegrad-common/src/components/SubIndustrySelector';
import IndustrySelector from 'elitegrad-common/src/components/IndustrySelector';
import EmployeeCountSelector from 'elitegrad-common/src/components/EmployeeCountSelector';
import InputError from 'elitegrad-common/src/components/InputError';
import { University } from 'elitegrad-common/src/assets/vectors';

const StyledForm = styled.form`
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 2.2rem;

  width: 100%;
  padding: 0 4rem;
  margin: 0 auto;
  padding-top: 2.5rem;
`;

const StyledButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 1.7rem;
  margin-top: 3.6rem;
  background-color: ${colors.gray_light_l10};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 1rem;
  background-color: ${colors.light_black};
`;

const Title = styled.span`
  font-size: 1.8rem;
  line-height: 2.1rem;
  font-family: 'RobotoBold';
  padding-bottom: 2.8rem;
  border-bottom: 1px solid ${colors.gray_light_l10};
`;

export interface CompanyProps {
  company: Company;
  newCompany?: boolean;
  onCancel?: () => void;
  onSave?: (company: Company) => void;
}

const EditCompanyForm: React.FC<CompanyProps> = ({
  newCompany,
  company,
  onCancel = () => undefined,
  onSave = () => undefined,
}) => {
  const formik = useFormik({
    initialValues: {
      name: company.name || '',
      description: company.description || '',
      address: company.address || '',
      phone: company.phone || '',
      website: company.website || '',
      subIndustries: company.subIndustries || [],
      industry: company.industry || '',
      employeesNumber: company.employeesNumber || '',
      logo: company.logo || '',
      photo: company.photo || '',
    },
    validate: (values) => {
      return {
        ...(!values.name && { name: 'Required' }),
        ...(!values.description && { description: 'Required' }),
        ...(!values.address && { address: 'Required' }),
        ...(!values.phone && { phone: 'Required' }),
        ...(!values.website && { website: 'Required' }),
        ...(!(values.subIndustries.length > 0) && { subIndustries: 'Required' }),
        ...(!values.industry && { industry: 'Required' }),
        ...(!values.employeesNumber && { employeesNumber: 'Required' }),
        ...(!values.logo && { logo: 'Required' }),
      };
    },
    onSubmit: (values) => {
      onSave({
        ...company,
        description: values.description,
        name: values.name,
        address: values.address,
        phone: values.phone,
        website: values.website,
        subIndustries: values.subIndustries,
        industry: values.industry,
        employeesNumber: values.employeesNumber,
        logo: values.logo,
        photo: values.photo,
      });
    },
  });

  return (
    <Container>
      <StyledForm onSubmit={formik.handleSubmit}>
        <Title>{newCompany ? 'New Company' : 'Edit Company Profile'}</Title>
        <FormInput>
          <PhotoUploader logo={formik.values.logo || University} onChange={(v) => formik.setFieldValue('logo', v)} />
          <InputError error={formik.errors.logo} />
        </FormInput>

        <FormInput bold label="Company Name">
          <TextInput value={formik.values.name} onChange={(v) => formik.setFieldValue('name', v)} />
          <InputError error={formik.errors.name} />
        </FormInput>
        <FormInput bold label="Description">
          <TextAreaInput value={formik.values.description} onChange={(v) => formik.setFieldValue('description', v)} />
          <InputError error={formik.errors.description} />
        </FormInput>
        <FormInput bold label="Address">
          <TextInput value={formik.values.address} onChange={(v) => formik.setFieldValue('address', v)} />
          <InputError error={formik.errors.address} />
        </FormInput>
        <FormInput bold label="Phone">
          <TextInput value={formik.values.phone} onChange={(v) => formik.setFieldValue('phone', v)} />
          <InputError error={formik.errors.phone} />
        </FormInput>
        <FormInput bold label="Website">
          <TextInput type={'url'} value={formik.values.website} onChange={(v) => formik.setFieldValue('website', v)} />
          <InputError error={formik.errors.website} />
        </FormInput>
        <FormInput bold label="Industry">
          <IndustrySelector value={formik.values.industry} onChange={(v) => formik.setFieldValue('industry', v)} />
          <InputError error={formik.errors.industry} />
        </FormInput>
        <FormInput bold label="Sub Industry">
          <SubIndustrySelector
            values={formik.values.subIndustries}
            onChange={(v) => formik.setFieldValue('subIndustries', v)}
          />
          <InputError error={formik.errors.subIndustries as string} />
        </FormInput>
        <FormInput bold label="Employees">
          <EmployeeCountSelector
            value={formik.values.employeesNumber}
            onChange={(v) => formik.setFieldValue('employeesNumber', v)}
          />
          <InputError error={formik.errors.employeesNumber} />
        </FormInput>
        <FormInput>
          <PhotoUploader
            big
            logo={formik.values.photo || University}
            onChange={(v) => formik.setFieldValue('photo', v)}
          />
          <InputError error={formik.errors.photo} />
        </FormInput>
      </StyledForm>
      <StyledButtons>
        <SecondaryButton big text="Cancel" onClick={onCancel} />
        <StyledPrimaryButton big submit text="Save" onClick={formik.handleSubmit} />
      </StyledButtons>
    </Container>
  );
};

export default EditCompanyForm;
