import React, { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';

import Job from 'elitegrad-common/src/types/Job';
import { useAuth, User } from 'elitegrad-common/src/utils/react-auth';
import NewJob from './NewJob';

import GET_JOBS from 'elitegrad-common/src/queries/getJobs.gql';
import CREATE_JOB from 'elitegrad-common/src/queries/createJob.gql';
import GET_COMPANY from 'elitegrad-common/src/queries/getCompany.gql';
import { CreateJob, CreateJobVariables } from 'elitegrad-common/src/generated/CreateJob';
import { GetJobs } from 'elitegrad-common/src/generated/GetJobs';
import { asJobRequirementParts } from 'elitegrad-common/src/transformers/jobRequirements';
import { recruit_job_requirement_insert_input } from 'elitegrad-common/src/generated/globalTypes';
import { GetCompanyOld, GetCompanyOldVariables } from 'elitegrad-common/src/generated/GetCompanyOld';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';
import { asCompany } from 'elitegrad-common/src/transformers/companies';

const NewJobController: React.FC = () => {
  const { user } = useAuth() as { user: User };

  const { data: dataCompany, loading } = useQuery<GetCompanyOld, GetCompanyOldVariables>(GET_COMPANY, {
    skip: !user?.sub,
    variables: {
      id: user?.sub || '',
    },
  });

  const [create] = useMutation<CreateJob, CreateJobVariables>(CREATE_JOB, {
    update: (cache, { data: createData }) => {
      const getJobsData = cache.readQuery<GetJobs>({
        query: GET_JOBS,
      });

      if (!getJobsData) {
        return;
      }

      const createdJobs = createData?.insert_recruit_job_posting?.returning || [];

      const newJobs = [...getJobsData.recruit_job_posting, ...createdJobs];

      cache.writeQuery({
        query: GET_JOBS,
        data: {
          recruit_job_posting: newJobs,
        },
      });
    },
  });

  const company = dataCompany?.recruit_company.map((c) => asCompany(c))[0] || null;

  const handleOnSave = useCallback(
    async (job: Job) => {
      if (!company?.accountId) return;
      const requirements = (job.requirements || [])
        .map((r) => asJobRequirementParts(r, job.id))
        .filter((r) => r !== null) as recruit_job_requirement_insert_input[];

      const variables = {
        account_id: company.accountId,
        creator_id: user.sub,
        active: true,
        creation_date: moment().toDate(),
        description: job.description,
        firm: company.name,
        job_type: job.jobType as string,
        position_title: job.title,
        deadline: job.deadline?.toDate(),
        region: job.region,
        requirements,
      };

      await create({ variables });
    },
    [create, user, company],
  );

  if (loading) return <SpinnerIsoType text="Loading..." fullPage />;
  return <NewJob onSave={handleOnSave} />;
};

export default NewJobController;
