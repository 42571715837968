import EventType from '../types/Event';
import { GetEvent_recruit_event_by_pk as GeneratedEventType } from '../generated/GetEvent';

const asFormEvent = (event: GeneratedEventType): EventType => {
  if (!event) return null;

  const {
    event_id,
    title,
    image,
    description,
    capacity,
    event_type,
    timezone,
    start_datetime,
    end_datetime,
    event_url,
    address,
  } = event;
  return {
    id: event_id,
    title,
    image: image || '',
    description: description || '',
    capacity,
    eventType: event_type,
    timezone,
    startDate: start_datetime,
    endDate: end_datetime,
    link: event_url || '',
    address: address || '',
  };
};

export { asFormEvent };
