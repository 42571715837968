import React from 'react';
import {
  CompanyContainer,
  Header,
  EditButton,
  CompanyCard,
  CompanyImage,
  CompanyInformation,
  CompanyImagePlaceHolder,
  CompanyHeader,
  CompanyLogo,
  CompanyName,
  CompanyDescription,
  CompanyDetails,
  CompanyDetailsColumn,
  CompanyAdressCard,
  CompanyAdressLine,
  AdressTitle,
  AdressText,
  DetailTitle,
  Tag,
  SubIndustries,
  HiddenDiv,
  BackgroundImage,
} from './CompanyDisplay.styled';
import EmptyPhotoCompany from 'elitegrad-common/src/assets/images/EmptyPhotoCompany.svg';
import EmptyLogoCompany from 'elitegrad-common/src/assets/images/EmptyLogoCompany.svg';
import BackgroundLogo from 'elitegrad-common/src/assets/vectors/BackgroundLogo.svg';

interface CompanyDisplayProps {
  logo: string | null;
  photo: string | null;
  name: string;
  industry: string;
  description: string;
  website: string;
  phone: string;
  address: string;
  num_employees: string;
  subindustries: string[];
  isManager: boolean;
  toggleEdit: (args: boolean) => void;
}

const CompanyDisplay: React.FC<CompanyDisplayProps> = ({
  logo,
  photo,
  name,
  industry,
  subindustries,
  description,
  num_employees,
  phone,
  address,
  website,
  isManager,
  toggleEdit,
}) => {
  return (
    <CompanyContainer>
      <BackgroundImage src={BackgroundLogo} />
      <Header>
        My Company
        {isManager && <EditButton onClick={() => toggleEdit(true)}>Edit</EditButton>}
      </Header>
      <CompanyCard>
        {photo && <CompanyImage image={photo} />}
        {!photo && <CompanyImagePlaceHolder image={EmptyPhotoCompany} />}
        <CompanyInformation>
          <CompanyHeader>
            <CompanyLogo image={logo || EmptyLogoCompany} />
            <CompanyName>{name}</CompanyName>
          </CompanyHeader>
          <CompanyDescription>{description}</CompanyDescription>
          <CompanyDetails>
            <CompanyDetailsColumn>
              <CompanyAdressCard>
                <CompanyAdressLine>
                  <AdressTitle>Address:</AdressTitle>
                  <AdressText>{address}</AdressText>
                </CompanyAdressLine>
                <CompanyAdressLine>
                  <AdressTitle>Phone:</AdressTitle>
                  <AdressText>{phone}</AdressText>
                </CompanyAdressLine>
                <CompanyAdressLine>
                  <AdressTitle>Website:</AdressTitle>
                  <AdressText>{website}</AdressText>
                </CompanyAdressLine>
              </CompanyAdressCard>
              <DetailTitle>Employees</DetailTitle>
              <Tag>{num_employees} Employees</Tag>
            </CompanyDetailsColumn>
            <CompanyDetailsColumn>
              <DetailTitle>Industry</DetailTitle>
              <Tag>{industry}</Tag>
              <DetailTitle>Sub industry</DetailTitle>
              <SubIndustries>
                {subindustries.map((subindustry) => (
                  <Tag key={subindustry}>{subindustry}</Tag>
                ))}
              </SubIndustries>
            </CompanyDetailsColumn>
          </CompanyDetails>
        </CompanyInformation>
      </CompanyCard>
      <HiddenDiv />
    </CompanyContainer>
  );
};

export default CompanyDisplay;
