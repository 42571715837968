import React, { useState } from 'react';
import styled from 'styled-components';
import AvatarEditor from 'react-avatar-editor';
import loadImage from 'blueimp-load-image';
import colors from '../theme/colors';

const StyledCustomUpload = styled.label<{ disabled?: boolean }>`
  height: 3.3rem;
  width: 19rem;
  padding: 0.2rem;
  margin: 0.45rem;
  border-radius: 1rem;
  border: ${({ disabled }) => (disabled ? `2px solid ${colors.gray_light_l2}` : `2px solid ${colors.dark_red_l2}`)};
  background: ${colors.white};

  font-size: 1.4rem;
  line-height: 2.1rem;
  color: ${({ disabled }) => (disabled ? colors.gray_light_l2 : colors.dark_red_l2)};
  font-family: 'RobotoBold';
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const StyledUploadFile = styled.input.attrs({
  type: 'file',
  accept: '.png, .jpg, .jpeg',
})`
  display: none;
`;

const StyledPhotoEditor = styled.div`
  margin: auto;
  max-width: 39rem;
`;
const StyledScaleInput = styled.input.attrs({
  type: 'range',
  step: '0.01',
  min: '1',
  max: '2',
  name: 'scale',
})`
  margin: 0 auto;
  margin-top: 1.3rem;
  width: 25rem;
  -webkit-appearance: none;
  background-color: ${colors.gray_light_l5};

  &::-webkit-slider-runnable-track {
    width: 25rem;
    height: 0.5rem;
    background: ${colors.gray_light_l5};
    border: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    background: ${colors.dark_red};
    margin-top: -0.5rem;
  }
`;

const StyledPhotoUploader = styled.div`
  padding: 2rem;
  border-radius: 10px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 4.5rem;
  justify-content: center;
`;

const PhotoUploader: React.FC<{ onChange: (val: string) => void; logo: string; big?: boolean }> = ({
  logo,
  onChange,
  big,
}) => {
  const [image, setImage] = useState<string>(logo);
  const [originalLogo] = useState<string>(logo);
  const [imageLoaded, setImageLoaded] = useState(!!logo);
  const [editor, setEditor] = useState<AvatarEditor>();
  const [zoom, setZoom] = useState('1');

  const editorRef = (editor: AvatarEditor) => setEditor(editor);

  const onZoom = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZoom(e.target.value);
  };

  const imageUploaded = (img: Event | HTMLCanvasElement | HTMLImageElement, file: File) => {
    setImage((img as HTMLCanvasElement).toDataURL(file.type));
    setImageLoaded(true);
  };

  const onImageChange = () => {
    if (editor) {
      const canvasScaled = big ? editor.getImage() : editor.getImageScaledToCanvas();
      const dataUrl = canvasScaled.toDataURL();
      onChange(dataUrl);
    }
  };

  const onFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageLoaded(false);

    if (e && e.target && e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      loadImage(
        e.target.files[0],
        (img) => {
          imageUploaded(img, file);
        },
        { orientation: true, canvas: true },
      );
    }
  };

  return (
    <StyledPhotoUploader>
      <StyledPhotoEditor>
        <AvatarEditor
          ref={editorRef}
          borderRadius={big ? 0 : 1000}
          color={[81, 72, 72, 0.4]} // RGBA
          scale={parseFloat(zoom)}
          image={image || ''}
          style={
            big
              ? { width: '25rem', height: '31rem', borderRadius: 10 }
              : { width: '25rem', height: '25rem', borderRadius: 10 }
          }
          onLoadSuccess={() => onImageChange()}
          onImageChange={onImageChange}
        />
      </StyledPhotoEditor>

      <StyledScaleInput onChange={onZoom} value={zoom} disabled={!imageLoaded}></StyledScaleInput>
      <ButtonsContainer>
        <StyledCustomUpload>
          <StyledUploadFile onChange={onFile} />
          upload photo
        </StyledCustomUpload>
        <StyledCustomUpload
          disabled={!imageLoaded || image === logo}
          onClick={() => {
            setZoom('1');
            setImage(originalLogo);
          }}
        >
          Cancel
        </StyledCustomUpload>
      </ButtonsContainer>
    </StyledPhotoUploader>
  );
};

export default PhotoUploader;
