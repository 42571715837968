import styled from 'styled-components';
import Select from 'react-select';

export const BestMatchesSelect = styled(Select)`
  max-width: 30rem;
  padding: 2rem;
`;

export const RaisedHandsTitle = styled.h2`
  padding: 2rem 0 0 2rem;
`;
