import React from 'react';
import styled from 'styled-components';

import { Group as GroupIcon } from '../../assets/vectors';
import { colors } from '../../theme';

const StyledCandidateCardNoGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.2rem 1.8rem;

  color: ${colors.neo_gray};
`;

const StyledNoGroupIcon = styled(GroupIcon)`
  width: 1.4rem;
  height: 1.4rem;

  & * {
    stroke: ${colors.neo_gray};
  }
`;

const StyledText = styled.div`
  margin-left: 0.8rem;

  font-size: 1.2rem;
  line-height: 1.5rem;
`;

interface CandidateCardNoGroupProps {
  candidateName: string;
}

const CandidateCardNoGroup: React.FC<CandidateCardNoGroupProps> = ({ candidateName }) => {
  return (
    <StyledCandidateCardNoGroup>
      <StyledNoGroupIcon />
      <StyledText>{candidateName} is not yet assigned to any group</StyledText>
    </StyledCandidateCardNoGroup>
  );
};

export default CandidateCardNoGroup;
