import styled from 'styled-components';
import { colors } from 'elitegrad-common/src/theme';

import { Trash as TrashIcon } from 'elitegrad-common/src/assets/vectors';

export const ShowStatus = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 2.4rem;
  grid-template-areas: 'icon legend';

  cursor: pointer;

  margin: auto;
`;

export const Icon = styled(TrashIcon)`
  grid-area: icon;

  width: 2.4rem;
  height: 2.4rem;

  & * {
    stroke: ${colors.red};
  }
`;

export const PopperContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  & > div:first-child {
    margin: auto;
  }

  & > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 1.5rem 2rem;
    min-width: 3.5rem;
    min-height: 7rem;

    background-color: ${colors.gray_extraLight};
  }
`;

export const Text = styled.div`
  grid-area: legend;

  margin-left: 1rem;

  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${colors.red};
`;
