import { useQuery, useMutation } from '@apollo/client';

import GET_COMPANY from 'elitegrad-common/src/queries/getCompany.gql';
import { GetCompany, GetCompanyVariables } from 'elitegrad-common/src/generated/GetCompany';

import INSERT_EVENT from 'elitegrad-common/src/queries/insertEvent.gql';
import { InsertEvent, InsertEventVariables } from 'elitegrad-common/src/generated/InsertEvent';

export const useNewEventQuery = (userID: string) => {
  const getCompany = useQuery<GetCompany, GetCompanyVariables>(GET_COMPANY, {
    variables: {
      id: userID || '',
    },
  });

  return getCompany;
};

export const useNewEventMutation = () => {
  const [insertEvent] = useMutation<InsertEvent, InsertEventVariables>(INSERT_EVENT, {
    refetchQueries: ['GetEventList'],
  });

  return insertEvent;
};
