import React from 'react';
import Page from '../Page';
import Header from 'elitegrad-common/src/components/Header';
import ProfileInformationItem from './ProfileInformationItem';
import { Container, ManagerTag } from './Profile.styled';

interface ProfileProps {
  fullname: string;
  email: string;
  companyName: string;
  isManager: boolean;
}

const Profile: React.FC<ProfileProps> = ({ fullname, email, isManager, companyName }) => {
  const headerComponent = <Header title={'My Profile'} />;
  return (
    <Page headerComponent={headerComponent}>
      <Container>
        <ProfileInformationItem text="Fullname" value={fullname} />
        <ProfileInformationItem text="Email" value={email} />
        <ProfileInformationItem text="Company" value={companyName} />
        {isManager && <ManagerTag>Manager</ManagerTag>}
      </Container>
    </Page>
  );
};

export default Profile;
