import moment from 'moment';

import { JobParts } from '../generated/JobParts';
import Job from '../types/Job';
import JobType from '../types/JobType';
import { asJobRequirement } from './jobRequirements';
import JobRequirement from '../types/JobRequirement';

const asJobType = (jobType: string): JobType => {
  if ((Object.values(JobType) as string[]).includes(jobType)) {
    return jobType as JobType;
  }

  // eslint-disable-next-line no-console
  console.warn(`Unknown job type: ${jobType}`);
  return JobType.UNKNOWN;
};

const asJob = (jobParts: JobParts): Job => {
  const candidatesUserIds = jobParts.raise_hands.map((rh) => rh.user_id);
  const requirements = jobParts.job_requirements.map(asJobRequirement).filter((v) => v !== null) as JobRequirement[];

  return {
    id: jobParts.job_posting_id,
    active: jobParts.active,
    title: jobParts.position_title,
    firm: jobParts.firm,
    description: jobParts.description,
    deadline: jobParts.deadline ? moment(jobParts.deadline) : null,
    region: jobParts.region,
    raisedHandsCount: candidatesUserIds.length,
    candidatesUserIds,
    jobType: asJobType(jobParts.job_type),
    hidden: !jobParts.public,
    requirements,
  };
};

export { asJob, asJobType };
