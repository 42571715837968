import * as yup from 'yup';

const URL_PATTERN = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

export const schema = yup.object({
  description: yup.string().required(),
  title: yup.string().required(),
  capacity: yup.number().when('isUnlimitedCapacity', {
    is: false,
    then: yup.number().typeError('Capacity must be a number').required().min(1, 'Capacity must be greater than 0'),
  }),
  timezone: yup.string().typeError('A timezone must be selected').required(),
  eventType: yup.string().required('must select an option'),
  address: yup.string().when('eventType', { is: 'In Person', then: yup.string().required() }),
  link: yup.string().when('eventType', {
    is: 'Online',
    then: yup.string().matches(URL_PATTERN, { message: 'Must be a valid url', excludeEmptyString: true }),
  }),
  endDate: yup
    .date()
    .typeError('End Date must be defined')
    .required()
    .when('startDate', (startDate, yup) => startDate && yup.min(startDate, 'End date must be after the start date')),
  startDate: yup
    .date()
    .typeError('Start Date must be defined')
    .required()
    .min(new Date(), 'Start Date must be greater than today'),
});
