import React from 'react';
import { useMutation } from '@apollo/client';

import {
  UpdateJobsStatusActive,
  UpdateJobsStatusActiveVariables,
} from 'elitegrad-common/src/generated/UpdateJobsStatusActive';
import UPDATE_JOB_STATUS_ACTIVE from 'elitegrad-common/src/queries/updateJobStatusActive.gql';

import JobStatusActivate from './JobStatusActive';

interface JobStatusActiveController {
  jobId: string;
  hasCandidates: boolean;
  isActive: boolean;
  setModalOpen: (status: boolean) => void;
}
const JobStatusActiveController: React.FC<JobStatusActiveController> = ({
  jobId,
  isActive,
  hasCandidates,
  setModalOpen,
}) => {
  const [updateJobStatusActivate, { loading }] = useMutation<UpdateJobsStatusActive, UpdateJobsStatusActiveVariables>(
    UPDATE_JOB_STATUS_ACTIVE,
  );

  const setActiveCallback = (active: boolean) => {
    const variables = {
      active: active,
      jobId: jobId,
    };
    updateJobStatusActivate({ variables });
  };
  return (
    <JobStatusActivate
      hasCandidates={hasCandidates}
      setModalOpen={setModalOpen}
      loading={loading}
      isJobActive={isActive}
      setActiveCallback={setActiveCallback}
    />
  );
};

export default JobStatusActiveController;
