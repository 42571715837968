import React from 'react';
import Select, { ValueType } from 'react-select';

import { selectorStyles } from '../theme';

export interface IndustrySelectorProps {
  value?: string | null;
  onChange?: (value?: string | null) => void;
}

const INDUSTRIES = ['Finance'];

const INDUSTRIES_OPTIONS = INDUSTRIES.map((industry) => ({
  value: industry,
  label: industry,
}));

const IndustrySelector: React.FC<IndustrySelectorProps> = ({ value = null, onChange = () => undefined }) => {
  const handleOnChange = (option: ValueType<{ label: string; value: string }>) => {
    option ? onChange((option as { label: string; value: string }).value) : onChange(null);
  };

  return (
    <Select
      value={INDUSTRIES_OPTIONS.find((o) => o.value === value)}
      options={INDUSTRIES_OPTIONS}
      styles={selectorStyles}
      onChange={handleOnChange}
      isClearable
    />
  );
};
export default IndustrySelector;
