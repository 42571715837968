import React, { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { asJob } from 'elitegrad-common/src/transformers/jobs';
import JobType from 'elitegrad-common/src/types/Job';
import EditJob from './EditJob';

import GET_JOB from 'elitegrad-common/src/queries/getJob.gql';
import UPDATE_JOB from 'elitegrad-common/src/queries/updateJob.gql';
import REMOVE_JOB_REQUIREMENTS from 'elitegrad-common/src/queries/removeJobRequirements.gql';
import INSERT_JOB_REQUIREMENTS from 'elitegrad-common/src/queries/insertJobRequirements.gql';

import { GetJob, GetJobVariables } from 'elitegrad-common/src/generated/GetJob';
import { UpdateJob, UpdateJobVariables } from 'elitegrad-common/src/generated/UpdateJob';
import {
  RemoveJobRequirements,
  RemoveJobRequirementsVariables,
} from 'elitegrad-common/src/generated/RemoveJobRequirements';
import {
  InsertJobRequirements,
  InsertJobRequirementsVariables,
} from 'elitegrad-common/src/generated/InsertJobRequirements';
import { asJobRequirementParts } from 'elitegrad-common/src/transformers/jobRequirements';
import { recruit_job_requirement_insert_input } from 'elitegrad-common/src/generated/globalTypes';

const EditJobController: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading } = useQuery<GetJob, GetJobVariables>(GET_JOB, {
    skip: !id,
    variables: {
      id: id || '',
    },
  });

  const [update] = useMutation<UpdateJob, UpdateJobVariables>(UPDATE_JOB);
  const [removeRequirements] = useMutation<RemoveJobRequirements, RemoveJobRequirementsVariables>(
    REMOVE_JOB_REQUIREMENTS,
  );

  const [insertRequirements] = useMutation<InsertJobRequirements, InsertJobRequirementsVariables>(
    INSERT_JOB_REQUIREMENTS,
  );

  const handleOnSave = useCallback(
    async (job: JobType) => {
      const variables = {
        job_posting_id: job.id,
        description: job.description,
        position_title: job.title,
        job_type: job.jobType as string,
        deadline: job.deadline?.toDate(),
        region: job.region,
      };
      await removeRequirements({ variables: { jobId: job.id } });
      const requirements = job.requirements
        .map((r) => asJobRequirementParts(r, job.id))
        .filter((r) => r !== null) as recruit_job_requirement_insert_input[];
      await insertRequirements({ variables: { requirements } });
      await update({ variables });
    },
    [update, removeRequirements, insertRequirements],
  );

  const jobs = data?.recruit_job_posting.map((rcp) => asJob(rcp)) || [];

  return <EditJob job={jobs[0]} loadingJob={loading} onSave={handleOnSave} />;
};

export default EditJobController;
