import React from 'react';
import { useFormik } from 'formik';

import DatePicker from 'elitegrad-common/src/components/DatePicker';
import FormInput, { LabelPosition } from 'elitegrad-common/src/components/FormInput';
import TextAreaInput from 'elitegrad-common/src/components/TextAreaInput';
import TextInput from 'elitegrad-common/src/components/TextInput';
import SecondaryButton from 'elitegrad-common/src/components/SecondaryButton';
import Job from 'elitegrad-common/src/types/Job';
import JobType from 'elitegrad-common/src/types/JobType';
import RegionPickerController from '../RegionPicker';
import RequirementsSideBarController from '../RequirementsSideBar';
import RadioButton from 'elitegrad-common/src/components/RadioButton';
import InputError from 'elitegrad-common/src/components/InputError';

import {
  StyledButtons,
  StyledForm,
  StyledPrimaryButton,
  Container,
  FormContainer,
  Title,
  Divider,
  RadioButtonsContainer,
} from './EditJobForm.styled';

export interface JobProps {
  job?: Job;
  onCancel?: () => void;
  onSave?: (job: Job) => void;
}

const EditJobForm: React.FC<JobProps> = ({ job = {} as Job, onCancel = () => undefined, onSave = () => undefined }) => {
  const formik = useFormik({
    initialValues: {
      title: job.title,
      jobType: job.jobType || JobType.FULL_TIME,
      description: job.description,
      deadline: job.deadline,
      regionName: job.region,
      requirements: job.requirements,
    },
    validate: (values) => ({
      ...(!values.title && { title: 'Required' }),
      ...(!values.description && { description: 'Required' }),
      ...(!values.regionName && { regionName: 'Required' }),
    }),
    onSubmit: (values) => {
      onSave({
        ...job,
        description: values.description || '',
        title: values.title || '',
        jobType: values.jobType,
        deadline: values.deadline,
        region: values.regionName,
        requirements: values.requirements,
      });
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <Container>
      <RequirementsSideBarController
        onChange={(v) => formik.setFieldValue('requirements', v)}
        value={formik.values.requirements}
      />
      <FormContainer>
        <Title>{!job?.title ? 'New Job' : 'Edit Job'}</Title>
        <Divider />
        <StyledForm onSubmit={formik.handleSubmit}>
          <FormInput label="Position title" bold>
            <TextInput value={formik.values.title} onChange={(v) => formik.setFieldValue('title', v)} />
            <InputError error={formik.errors.title} />
          </FormInput>
          <FormInput label="Place" bold>
            <RegionPickerController
              value={formik.values.regionName}
              onChange={(v) => formik.setFieldValue('regionName', v)}
            />
            <InputError error={formik.errors.regionName} />
          </FormInput>
          <FormInput label="Deadline" bold>
            <DatePicker
              id="jobDeadline"
              value={formik.values.deadline}
              onChange={(v) => formik.setFieldValue('deadline', v)}
            />
            <InputError error={formik.errors.deadline} />
          </FormInput>
          <RadioButtonsContainer>
            <FormInput label="Internship" labelPosition={LabelPosition.Right}>
              <RadioButton
                checked={formik.values.jobType === JobType.INTERNSHIP}
                onClick={() => formik.setFieldValue('jobType', JobType.INTERNSHIP)}
              />
            </FormInput>
            <FormInput label="Full Time" labelPosition={LabelPosition.Right}>
              <RadioButton
                checked={formik.values.jobType === JobType.FULL_TIME}
                onClick={() => formik.setFieldValue('jobType', JobType.FULL_TIME)}
              />
            </FormInput>
            <InputError error={formik.errors.jobType} />
          </RadioButtonsContainer>

          <FormInput label="Description" bold>
            <TextAreaInput
              value={formik.values.description}
              onChange={(v) => formik.setFieldValue('description', v)}
              rows={5}
            />
            <InputError error={formik.errors.description} />
          </FormInput>
          <StyledButtons>
            <SecondaryButton big text="Cancel" onClick={onCancel} />
            <StyledPrimaryButton submit big dark text="Save" />
          </StyledButtons>
        </StyledForm>
      </FormContainer>
    </Container>
  );
};

export default EditJobForm;
