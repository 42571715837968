import React from 'react';
import styled from 'styled-components';

import colors from '../theme/colors';

const StyledInputError = styled.div`
  height: 1.2rem;

  color: ${colors.red};
  font-size: 1.2rem;
  font-weight: 600;

  margin: 0.5rem 0 1.5rem 0;
`;

const InputError: React.FC<{ error?: string }> = ({ error }) => {
  return <StyledInputError>{error && error.length > 0 ? error : ''}</StyledInputError>;
};

export default InputError;
