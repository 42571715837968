import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage as FormErrorMessage } from '@hookform/error-message';
import TimezoneSelect from 'react-timezone-select';
import DateTimePicker from 'react-datetime-picker';

import FormInput from 'elitegrad-common/src/components/FormInput';
import { HookTextInput } from 'elitegrad-common/src/components/TextInput';
import { HookTextAreaInput } from 'elitegrad-common/src/components/TextAreaInput';
import ViewTitle from 'elitegrad-common/src/components/ViewTitle';
import { HookCheckbox } from 'elitegrad-common/src/components/Checkbox';
import PhotoUploader from 'elitegrad-common/src/components/PhotoUploader';
import FormRadioButtonGroup from 'components/common/FormRadioButtonGroup';
import CalendarIcon from 'elitegrad-common/src/assets/vectors/Calendar.svg';
import { EGLogo } from 'elitegrad-common/src/assets/vectors';
import EventType from 'elitegrad-common/src/types/Event';
import CancelButton from 'elitegrad-common/src/components/SecondaryButton';

import { schema } from './EventForm.validations';

import {
  Form,
  Spacing,
  UnlimitedCapacityContainer,
  UnlimitedCapacityLabel,
  ErrorMessage,
  DatePickerContainer,
  DateTimeRangeContainer,
  SaveButton,
  SubmitButtonsContainer,
} from './EventForm.styled';

const setDefaultValues = (defaultValues?: EventType | null) => {
  return {
    title: defaultValues?.title || '',
    image: defaultValues?.image || EGLogo,
    description: defaultValues?.description || '',
    capacity: defaultValues?.capacity || 0,
    isUnlimitedCapacity: defaultValues?.capacity === 0,
    eventType: defaultValues?.eventType || 'Online',
    timezone: defaultValues?.timezone || '',
    startDate: defaultValues?.startDate ? new Date(defaultValues?.startDate) : new Date(),
    endDate: defaultValues?.endDate ? new Date(defaultValues?.endDate) : new Date(),
    link: defaultValues?.link || '',
    address: defaultValues?.address || '',
  };
};

interface EventFormProps {
  submit: (data: EventType) => void;
  onCancel: () => void;
  pageTitle?: string;
  defaultValues?: EventType | null;
}

const EventForm: React.FC<EventFormProps> = ({ submit, onCancel, pageTitle = 'New Event', defaultValues }) => {
  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: setDefaultValues(defaultValues),
  });
  const { register, handleSubmit, errors, watch, setValue, control } = formMethods;

  register('image');
  const { isUnlimitedCapacity, image, eventType } = watch(['isUnlimitedCapacity', 'image', 'eventType']);

  useEffect(() => {
    if (isUnlimitedCapacity) setValue('capacity', 0);
  }, [isUnlimitedCapacity, setValue]);

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(submit)}>
        <ViewTitle> {pageTitle} </ViewTitle>
        <Spacing />

        <FormInput label="Title">
          <HookTextInput register={register} name="title" />
          <FormErrorMessage as={ErrorMessage} name="title" />
        </FormInput>
        <Spacing />

        <FormInput label="Image">
          <PhotoUploader logo={image || EGLogo} onChange={(image) => setValue('image', image)} />
        </FormInput>
        <Spacing />

        <FormInput label="Description">
          <HookTextAreaInput register={register} name="description" />
          <FormErrorMessage errors={errors} as={ErrorMessage} name="description" />
        </FormInput>
        <Spacing />

        <UnlimitedCapacityContainer>
          <UnlimitedCapacityLabel>Is capacity unlimited?</UnlimitedCapacityLabel>
          <HookCheckbox name="isUnlimitedCapacity" register={register} />
        </UnlimitedCapacityContainer>

        {!isUnlimitedCapacity && (
          <FormInput label="Capacity">
            <HookTextInput type="number" register={register} name="capacity" />
            <FormErrorMessage errors={errors} as={ErrorMessage} name="capacity" />
          </FormInput>
        )}
        <Spacing />

        <FormRadioButtonGroup
          options={['Online', 'In Person']}
          register={register}
          name="eventType"
          checkedValue={eventType}
        />
        <FormErrorMessage errors={errors} as={ErrorMessage} name="eventType" />
        <Spacing />

        {eventType === 'Online' ? (
          <FormInput label="Link to the meeting">
            <HookTextInput key="link" name="link" register={register} />
            <FormErrorMessage errors={errors} as={ErrorMessage} name="link" />
          </FormInput>
        ) : (
          eventType === 'In Person' && (
            <FormInput label="Address">
              <HookTextInput key="address" name="address" register={register} />
              <FormErrorMessage errors={errors} as={ErrorMessage} name="address" />
            </FormInput>
          )
        )}
        <Spacing />
        <FormInput label="Timezone">
          <Controller
            name="timezone"
            control={control}
            defaultValue={defaultValues?.timezone}
            render={({ onChange, value }) => <TimezoneSelect onChange={({ value }) => onChange(value)} value={value} />}
          />
          <FormErrorMessage errors={errors} as={ErrorMessage} name="timezone" />
        </FormInput>

        <Spacing />
        <DateTimeRangeContainer>
          <DatePickerContainer>
            <FormInput label="Start Date">
              <Controller
                name="startDate"
                control={control}
                defaultValue={defaultValues?.startDate}
                render={({ onChange, value }) => (
                  <DateTimePicker
                    value={value}
                    onChange={onChange}
                    calendarIcon={<img src={CalendarIcon} alt={'calendar-icon'} />}
                  />
                )}
              />
            </FormInput>
          </DatePickerContainer>

          <DatePickerContainer>
            <FormInput label="End Date">
              <Controller
                name="endDate"
                control={control}
                defaultValue={defaultValues?.endDate}
                render={({ onChange, value }) => (
                  <DateTimePicker
                    value={value}
                    onChange={onChange}
                    calendarIcon={<img src={CalendarIcon} alt={'calendar-icon'} />}
                  />
                )}
              />
            </FormInput>
          </DatePickerContainer>
        </DateTimeRangeContainer>

        <FormErrorMessage errors={errors} as={ErrorMessage} name="startDate" />

        <FormErrorMessage errors={errors} as={ErrorMessage} name="endDate" />

        <Spacing />
        <SubmitButtonsContainer>
          <CancelButton big text="Cancel" onClick={onCancel} />
          <SaveButton big submit text="Save" />
        </SubmitButtonsContainer>
      </Form>
    </FormProvider>
  );
};

export default EventForm;
