import React from 'react';
import Select, { ValueType } from 'react-select';

import { selectorStyles } from 'elitegrad-common/src/theme';
import Region from 'elitegrad-common/src/types/Region';

interface Option {
  label: string;
  value: string;
}

const buildOptions = (regions: Region[]): Option[] => regions.map(buildOption);

const buildOption = (region: Region): Option => ({ value: region.name, label: region.name });

export interface RegionPickerProps {
  value: string | null; // The name of the region
  regions: Region[];
  loading?: boolean;
  onChange?: (value: string | null) => void;
}

const RegionPicker: React.FC<RegionPickerProps> = ({ value, regions, loading = false, onChange = () => undefined }) => {
  const options = buildOptions(regions);
  const selectedOption = value ? { value, label: value } : null;

  const handleOnChange = (option: ValueType<Option, false>) => {
    const region = option ? (option as Option).value : null;
    onChange(region);
  };

  return (
    <Select
      value={selectedOption}
      options={options}
      styles={selectorStyles}
      onChange={handleOnChange}
      isClearable
      isLoading={loading}
    />
  );
};

export default RegionPicker;
