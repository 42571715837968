import styled from 'styled-components';
import colors from 'elitegrad-common/src/theme/colors';
import { InternshipTagNew } from 'elitegrad-common/src/components/InternshipTag';

export const Content = styled.div`
  display: flex;
  align-items: baseline;

  width: 100%;
  margin-top: 2rem;
  padding: 2rem;
`;

export const Description = styled.pre`
  min-height: calc(2.8rem * 6);
  margin-right: 2rem;

  overflow: scroll;

  font: inherit;
  font-size: 1.6rem;
  line-height: 2.8rem;
  white-space: pre-wrap;

  color: ${colors.gray_dark};
`;

export const Deadline = styled.div`
  color: ${colors.gray_dark};

  font-size: 1.6rem;
  line-height: 2.8rem;
`;

export const InfoSection = styled.div`
  :nth-child(2) {
    margin-top: 6.8rem;
    margin-bottom: 2.5rem;
  }
`;

export const StyledInternshipTag = styled(InternshipTagNew)`
  margin: 1.8rem 0 1.6rem;
`;

export const Container = styled.div`
  padding: 2rem;
`;

export const Label = styled.div`
  color: ${colors.black};

  font-size: 1.5rem;
  line-height: 1.8rem;
`;

export const Location = styled.div`
  color: ${colors.gray_dark};

  font-size: 1.6rem;
  line-height: 2.8rem;
`;

export const Title = styled.div`
  font-size: 3.6rem;
  line-height: 4.2rem;
  color: ${colors.black};
`;

export const RightSection = styled.div`
  margin-left: 2.4rem;
`;

export const LeftSection = styled.div`
  width: 50rem;
`;
