import styled from 'styled-components';
import colors from 'elitegrad-common/src/theme/colors';

export const HeaderOptions = styled.div<{ isEventSelected?: boolean }>`
  display: flex;
  opacity: ${({ isEventSelected }) => (isEventSelected ? '100%' : '20%')};
  ${({ isEventSelected }) => !isEventSelected && 'pointer-events: none;'}

  & > div,
  a {
    border-right: 1px solid ${colors.gray_light_l2};
    padding: 0 3rem;
  }

  & > div:last-of-type {
    border-right: none;
  }
`;

export const Title = styled.div`
  font-family: 'RobotoBold';
  font-size: 3.4rem;
`;

export const OptionsSection = styled.div`
  display: flex;

  max-width: 65rem;
  min-height: 3.5rem;
`;
