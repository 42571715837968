import { colors } from 'elitegrad-common/src/theme';
import styled from 'styled-components';
export const ToggleControl = styled.div`
  margin-left: 1.6rem;
`;

export const NoCandidateMessage = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.gray_light_l2};
  justify-content: center;
  align-items: center;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 3rem;
  text-align: center;
  margin-right: 3rem;
`;

export const Container = styled.div`
  padding: 2rem;
`;

export const PlaceholderIcon = styled.img`
  width: 4rem;
  height: 4rem;
  margin-bottom: 2rem;
`;
