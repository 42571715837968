import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Paths } from 'routes';
import { dateToTimestamp } from 'utils/dates';

import EventForm from 'components/events/EventForm';
import { useEditEventQuery, useEditEventMutation } from './EditEvent.hook';
import Page from '../../Page';
import Header from 'elitegrad-common/src/components/Header';
import EventType from 'elitegrad-common/src/types/Event';
import { asFormEvent } from 'elitegrad-common/src/transformers/formEvent';
import Spinner from 'elitegrad-common/src/components/Spinner';

interface RouteProps {
  id: string;
}

const EditEvent = () => {
  const history = useHistory();
  const { id } = useParams<RouteProps>();
  const { data: eventInfo, loading } = useEditEventQuery(id);
  const updateEvent = useEditEventMutation();

  const submit = async (data: EventType) => {
    if (!eventInfo?.recruit_event_by_pk?.event_id) return;

    await updateEvent({
      variables: {
        ...data,
        capacity: data.capacity || 0,
        eventID: eventInfo.recruit_event_by_pk.event_id,
        startDate: dateToTimestamp(data.startDate),
        endDate: dateToTimestamp(data.endDate),
      },
    });

    history.push(Paths.events);
  };

  const onCancel = () => {
    history.goBack();
  };

  const defaultValues = eventInfo?.recruit_event_by_pk && asFormEvent(eventInfo.recruit_event_by_pk);

  return (
    <Page headerComponent={<Header backLink />}>
      {loading ? (
        <Spinner />
      ) : (
        <EventForm submit={submit} onCancel={onCancel} pageTitle="Edit Event" defaultValues={defaultValues} />
      )}
    </Page>
  );
};

export default EditEvent;
