import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import './index.css';
import App from './App';
import { Paths } from './routes';
import * as serviceWorker from './serviceWorker';
import { Provider as ApolloProvider } from 'elitegrad-common/src/utils/react-apollo';
import { Auth0Provider } from 'elitegrad-common/src/utils/react-auth';
import { UsePhotoProvider } from 'elitegrad-common/src/utils/usePhoto';
import { CookiesProvider } from 'react-cookie';

const VALID_ROLES = ['recruiter', 'manager', 'support-admin'];

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
    clientID={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    callbackPath={Paths.authCallback}
    loginPath={Paths.authLogin}
    logoutPath={Paths.authLogout}
    validRoles={VALID_ROLES}
  >
    <Router>
      <CookiesProvider>
        <ApolloProvider>
          <UsePhotoProvider>
            <App />
          </UsePhotoProvider>
        </ApolloProvider>
      </CookiesProvider>
    </Router>
  </Auth0Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
