import styled from 'styled-components';
import colors from 'elitegrad-common/src/theme/colors';

import { EditJobIcon } from 'elitegrad-common/src/assets/vectors';

export const CardContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;

  width: 28.5rem;
  height: 21.8rem;

  padding: 0.8rem 1.2rem;

  border: ${({ isSelected }) => (isSelected ? `2px solid ${colors.red}` : `1px solid ${colors.gray_light_l3}`)};
  border-radius: 12px;

  cursor: pointer;
`;

export const JobState = styled.div`
  min-height: 1.9rem;
  display: flex;
  align-items: center;
`;

export const ActiveTag = styled.span<{ isActive }>`
  margin-right: 0.5rem;

  font-family: 'RobotoMedium';
  font-size: 1.1rem;
  color: ${({ isActive }) => (isActive ? colors.green_light : colors.red)};
  text-transform: uppercase;
`;

export const ExpirationTag = styled.span`
  font-family: 'RobotoMedium';
  font-size: 1rem;
  color: ${colors.gray};
  line-height: 1.4rem;
`;

export const Region = styled.div`
  margin-top: 1rem;

  font-family: 'RobotoMedium';
  font-size: 1rem;
`;

export const Title = styled.div`
  font-family: 'RobotoMedium';
  font-size: 1.4rem;
  margin-top: 0.3rem;
`;

export const IntershipTag = styled.div<{ isIntern?: boolean }>`
  width: 7rem;
  padding: 0.4rem 0;
  margin-top: 1rem;
  background-color: ${({ isIntern }) => (isIntern ? colors.red : colors.black)};
  border-radius: 4px;

  font-family: 'RobotoBlack';
  font-size: 0.9rem;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.white};
`;

export const Description = styled.div`
  overflow-y: scroll;
  max-width: 26rem;
  text-overflow: ellipsis;
  overflow-x: hidden;
  margin-top: 1.2rem;

  font-size: 1.2rem;
  line-height: 2rem;
  opacity: 0.7;

  a {
    text-decoration: underline;
  }
`;

export const RaisedHandsCount = styled.div`
  font-family: 'RobotoBlack';
  margin-top: auto;
  font-size: 1.1rem;
  padding: 1.2rem 0;
`;

export const EditIcon = styled(EditJobIcon)<{ selected?: boolean }>`
  position: absolute;
  margin: 1rem;
  top: 0;
  right: 0;

  height: 2rem;
  width: 2rem;

  & > path {
    fill: ${({ selected }) => (selected ? colors.red : colors.gray)};
  }
`;

export const HiddenTag = styled.div`
  display: flex;
  align-items: center;
  width: 52px;
  height: 16px;
  font-family: 'RobotoBlack';
  justify-content: space-around;
  align-items: center;
  font-size: 0.8rem;
  border: 1px solid ${colors.gray_dark_2};
  color: ${colors.gray_dark_2};
  border-radius: 4px;
  margin-left: 1rem;
  padding: 0 0.3rem;

  span {
    line-height: 130%;
    margin-top: 0.1rem;
  }
`;
