import React, { useState } from 'react';

import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import SecondaryButton from 'elitegrad-common/src/components/SecondaryButton';
import Spinner from 'elitegrad-common/src/components/Spinner';
import HeaderPopper from 'elitegrad-common/src/components/HeaderPopper';
import { usePopper } from 'elitegrad-common/src/utils/usePopper';

import { Icon, PopperContentContainer, ShowStatus, Text } from './PostStatusDelete.styled';

const LoadingPopperContent: React.FC = () => {
  return (
    <>
      <Spinner />
      <div>Deleting Post ...</div>
    </>
  );
};

const PopperContent: React.FC<{ onDelete: () => void; setShow: (show: boolean) => void }> = ({ setShow, onDelete }) => {
  return (
    <>
      <div>Are you sure you want to delete this Post?</div>
      <div>
        <SecondaryButton big onClick={() => setShow(false)} text={'No, Cancel'} />
        <PrimaryButton dark big onClick={onDelete} text={'Yes, Delete'} />
      </div>
    </>
  );
};

interface PostStatusDeleteProps {
  loading: boolean;
  onDelete: () => void;
}

const PostStatusDelete: React.FC<PostStatusDeleteProps> = ({ loading, onDelete }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

  const { popperOpen, togglePopper, setPopperStatus } = usePopper('PostStatusDelete');

  return (
    <>
      <ShowStatus onClick={togglePopper} ref={setReferenceElement}>
        <Icon />
        <Text>Delete</Text>
      </ShowStatus>
      <HeaderPopper referenceElement={referenceElement} show={popperOpen}>
        <PopperContentContainer>
          {loading ? <LoadingPopperContent /> : <PopperContent setShow={setPopperStatus} onDelete={onDelete} />}
        </PopperContentContainer>
      </HeaderPopper>
    </>
  );
};

export default PostStatusDelete;
