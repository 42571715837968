import gql from 'graphql-tag';

import { JobParts } from './fragments.gql';

export default gql`
  mutation CreateJob(
    $account_id: String!
    $active: Boolean!
    $creation_date: date
    $creator_id: String!
    $description: String!
    $firm: String!
    $position_title: String!
    $job_type: String!
    $deadline: date
    $region: String!
    $requirements: [recruit_job_requirement_insert_input!]!
  ) {
    insert_recruit_job_posting(
      objects: {
        account_id: $account_id
        active: $active
        creation_date: $creation_date
        creator_id: $creator_id
        description: $description
        firm: $firm
        position_title: $position_title
        job_type: $job_type
        deadline: $deadline
        region: $region
        job_requirements: { data: $requirements }
      }
    ) {
      returning {
        ...JobParts
      }
    }
  }
  ${JobParts}
`;
