import colors from '../../theme/colors';
import styled, { css } from 'styled-components';

export const CompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 4.5rem 0;
  height: 585px;
  align-items: center;
`;

export const BackgroundImage = styled.img`
  width: 49vw;
  height: 55vh;
  position: absolute;
  bottom: 1vh;
  right: -6vw;
`;

export const CompanyCard = styled.div`
  display: flex;
  max-width: 104rem;
  height: 100%;
  position: relative;
  top: 55%;
  transform: translateY(-45%);
`;

export const CompanyLogo = styled.div<{ image: string | null }>`
  ${({ image }) =>
    image &&
    css`
      background: url(${image}) no-repeat center;
      background-size: cover;
    `};

  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  margin-right: 2.5rem;
`;

export const CompanyLogoEmpty = styled.div<{ image: string }>`
  ${({ image }) =>
    image &&
    css`
      background: url(${image}) no-repeat center;
    `};

  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  margin-right: 2.5rem;
`;

export const CompanyImage = styled.div<{ image: string | null }>`
  ${({ image }) =>
    image &&
    css`
      background: url(${image}) no-repeat center;
      background-size: cover;
    `};
  border: 0.1rem solid ${colors.gray_light_l8};
  border-right: none;
  height: 100%;
  min-width: 24rem;
  max-width: 35rem;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
`;

export const CompanyImagePlaceHolder = styled.div<{ image: string }>`
  ${({ image }) =>
    image &&
    css`
      background: url(${image}) no-repeat center;
    `};

  height: 100%;
  border: 0.1rem solid ${colors.gray_light_l8};
  border-right: none;
  min-width: 24rem;
  max-width: 35rem;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
`;

export const CompanyDescription = styled.div`
  max-width: 85%;
  min-height: 6rem;
  max-height: 8rem;
  font-family: 'Roboto';
  font-size: 1.4rem;
  line-height: 2.1rem;
  overflow: scroll;
  margin-top: 3rem;
  color: ${colors.light_black_l3};
`;

export const Tag = styled.div`
  width: fit-content;
  min-width: 3rem;
  max-width: 25rem;
  height: 2.4rem;
  overflow: hidden;
  margin: 0.2rem 0.5rem 0.2rem 0;
  padding: 0.4rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${colors.white};
  background-color: ${colors.gray_light_l9};
  border-radius: 4px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'RobotoBold';
  font-size: 3.9rem;
  line-height: 4.6rem;
  margin-bottom: 4rem;
  width: 100%;
`;

export const EditButton = styled.button<{ onClick: (bool) => void }>`
  border: none;
  outline: none;
  color: ${colors.white};
  background-color: ${colors.red};
  width: 9rem;
  height: 4rem;
  border-radius: 0.4rem;
  cursor: pointer;
`;

export const CompanyName = styled.span`
  font-family: 'RobotoBold';
  font-size: 4rem;
  line-height: 4rem;
  color: ${colors.light_black};
`;

export const DetailTitle = styled.span`
  font-family: 'RobotoBlack';
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: ${colors.light_black};
  margin: 1.5rem 0;
`;
export const CompanyHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const SubIndustries = styled.div`
  display: flex;
  max-width: 40rem;
  flex-wrap: wrap;
`;

export const CompanyDetails = styled.div`
  display: flex;
  width: 100%;
`;

export const CompanyDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 3.5rem;
  margin-top: 2.3rem;
`;

export const AdressTitle = styled.span`
  font-family: 'RobotoBlack';
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: ${colors.black};
  margin-right: 0.5rem;
`;

export const AdressText = styled.span`
  font-family: 'Roboto';
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: ${colors.gray_dark};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CompanyAdressCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${colors.gray_l2};
  height: 11rem;
  max-width: 35rem;
  border-radius: 4px;
`;

export const CompanyAdressLine = styled.div`
  display: flex;
  margin: 0.8rem 1rem;
`;

export const CompanyInformation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem 0;
  padding-left: 4.3rem;
  border: 0.1rem solid ${colors.gray_light_l8};
  border-left: none;
  border-radius: 13px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  @media (min-width: 1440px) {
    padding: 6rem 0;
    padding-left: 4.3rem;
  }
`;

export const HiddenDiv = styled.div`
  visibility: hidden;
  min-height: 5rem;
  max-height: 10rem;
`;
