import React, { useState, useEffect } from 'react';

import Page from '../Page';
import ConversationListController from 'elitegrad-common/src/components/messaging/conversations/ConversationListController';
import ChatController from 'elitegrad-common/src/components/messaging/chat/ChatController';
import { messagingPath } from 'routes';
import { useLazyQuery } from '@apollo/client';
import GET_CANDIDATE from 'elitegrad-common/src/queries/getCandidate.gql';
import { GetCandidate, GetCandidateVariables } from 'elitegrad-common/src/generated/GetCandidate';
import { asCandidateWithProfileImage } from 'elitegrad-common/src/transformers/candidates';
import CandidateCard from './CandidateProfileInChat';

import { Container, StyledMessaging, StyledLeft, ContainerHeader, Title, CloseIcon } from './Messaging.styled';

const Messaging: React.FC = () => {
  const [studentProfileOpen, setStudentProfileOpen] = useState(false);

  const [getCandidate, { data }] = useLazyQuery<GetCandidate, GetCandidateVariables>(GET_CANDIDATE);

  const candidate = data?.grad_profile.map((c) => asCandidateWithProfileImage(c))[0];

  useEffect(() => {
    const locationParts = window.location.pathname.split('/');
    const userId = locationParts[locationParts.length - 1].replace('%7C', '|');
    getCandidate({ variables: { admin: false, userId: userId } });
  }, [window.location.pathname]); //eslint-disable-line

  return (
    <Page>
      <StyledMessaging studentProfileOpen={studentProfileOpen}>
        <StyledLeft>
          <ConversationListController generateMessagingPath={messagingPath} />
        </StyledLeft>
        <ChatController setStudentProfileOpen={setStudentProfileOpen} generateCandidatePath={messagingPath} />
        {studentProfileOpen && candidate && (
          <Container>
            <ContainerHeader>
              <Title>Student Information</Title>
              <CloseIcon onClick={() => setStudentProfileOpen(false)}>X</CloseIcon>
            </ContainerHeader>
            <CandidateCard key={candidate.userId} candidate={candidate} />
          </Container>
        )}
      </StyledMessaging>
    </Page>
  );
};

export default Messaging;
