import React from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';

import FormInput from 'elitegrad-common/src/components/FormInput';
import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import TextInput from 'elitegrad-common/src/components/TextInput';
import PasswordInput from 'elitegrad-common/src/components/PasswordInput';
import colors from 'elitegrad-common/src/theme/colors';
import { useAuth } from 'elitegrad-common/src/utils/react-auth';
import LandingSection from './LandingSection';
import { EGIsotype } from 'elitegrad-common/src/assets/vectors';

const StyledLeftPanel = styled.div`
  margin: 20rem auto 0;
  width: 34rem;

  & > * {
    margin-bottom: 3rem;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

const StyledRightPanel = styled.div`
  display: none;

  width: 50%;
  max-width: 50%;

  @media only screen and (min-width: 900px) {
    display: block;
  }
`;

const StyledLandingSection = styled(LandingSection)`
  height: 100%;
`;

const StyledLogin = styled.div`
  display: flex;
  flex-direction: row;

  height: 100vh;
`;

const StyledForm = styled.form`
  & > * {
    margin-bottom: 3rem;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  height: 4rem;
  font-weight: bold;
  font-size: 15px;
`;

const StyledTitle = styled.div`
  font-size: 4.9rem;
  line-height: 5.7rem;
  color: ${colors.black};
`;

const StyledError = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.8rem;
  color: ${colors.red};
`;

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled.div`
  font-family: 'RobotoLight';
  font-size: 24px;
  margin: 40px 0;
  text-align: center;
  line-height: 40px;
  white-space: pre-wrap;
`;

const onSafariOrIphone = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const chrome = /chrome/.test(userAgent) && /Google/.test(navigator.vendor);
  const ios = /(ipod|iphone|ipad)/.test(userAgent);
  const safari = /safari/.test(userAgent) && !chrome && !ios;
  return ios || safari;
};

const Login: React.FC = () => {
  const { doLogin, error, doUniversalLogin } = useAuth();

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    onSubmit: (values) => {
      doLogin(values.email, values.password);
    },
  });

  return (
    <StyledLogin>
      <StyledLeftPanel>
        {onSafariOrIphone() ? (
          <StyledBox>
            <EGIsotype width="64px" height="70px" />
            <StyledText>{`If you're a recruiter \n click here to go to Log in`}</StyledText>
            <StyledPrimaryButton text="Go to Log In" onClick={() => doUniversalLogin()}></StyledPrimaryButton>
          </StyledBox>
        ) : (
          <>
            <StyledTitle>Log in</StyledTitle>
            <StyledForm onSubmit={formik.handleSubmit}>
              <FormInput label="Email:">
                <TextInput
                  placeholder="you@example.com"
                  value={formik.values.email}
                  onChange={(v) => formik.setFieldValue('email', v)}
                />
              </FormInput>
              <FormInput label="Password:">
                <PasswordInput
                  placeholder="Enter your password"
                  value={formik.values.password}
                  onChange={(v) => formik.setFieldValue('password', v)}
                />
              </FormInput>
              <StyledPrimaryButton text="Login" big submit />
              {error && <StyledError>Invalid credentials</StyledError>}
            </StyledForm>
          </>
        )}
      </StyledLeftPanel>
      <StyledRightPanel>
        <StyledLandingSection />
      </StyledRightPanel>
    </StyledLogin>
  );
};

export default Login;
