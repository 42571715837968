import React from 'react';
import Select, { ValueType } from 'react-select';

import { selectorStyles } from '../theme';

export interface EmployeeCountSelectorProps {
  value?: null | string;
  onChange?: (value?: string | null) => void;
}

const EMPLOYEE_COUNT = ['Not Disclosed', '0 - 25', '25 - 50', '50 - 100', '100 - 250', '250+'];

const EMPLOYEE_COUNT_OPTIONS = EMPLOYEE_COUNT.map((industry) => ({
  value: industry,
  label: industry,
}));

const EmployeeCountSelector: React.FC<EmployeeCountSelectorProps> = ({ value = null, onChange = () => undefined }) => {
  const handleOnChange = (option: ValueType<{ label: string; value: string }, false>) => {
    option ? onChange((option as { label: string; value: string }).value) : onChange(null);
  };

  return (
    <Select
      value={EMPLOYEE_COUNT_OPTIONS.find((o) => o.value === value)}
      options={EMPLOYEE_COUNT_OPTIONS}
      styles={selectorStyles}
      onChange={handleOnChange}
      isClearable
    />
  );
};
export default EmployeeCountSelector;
