import React, { useState } from 'react';

import { INITIAL_OFFSET } from 'elitegrad-common/src/components/pagination/PaginationState';
import { asJobType } from 'elitegrad-common/src/transformers/jobs';
import Jobs from './Jobs';
import {
  useGetJobDetailCandidatesValidGPAFromIdsQuery,
  useGetJobDismissRecipientsByIdLazyQuery,
  useGetJobsListQuery,
} from 'elitegrad-common/src/generated/graphql';
import moment from 'moment';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';
import { HeaderContainer, HorizontalSeparator, Container, PlaceholderContainer } from './Jobs.styled';
import Page from '../../Page';
import JobHeader from '../JobHeader';
import { isActive, isExpired, relativeDeadline } from './Jobs.utils';
import { MessageNewModal } from 'elitegrad-common/src/components/messaging';
import { asJobRequirement, asRecipient } from '../JobDetail/JobDetail.utils';
import JobCandidatesList from './JobCandidatesList';
import Modal from 'elitegrad-common/src/components/Modal';
import JobEdit from '../JobDetail/EditJobForm';
import JobType from 'elitegrad-common/src/types/JobType';
import JobObjectType from 'elitegrad-common/src/types/Job';
import JobRequirement from 'elitegrad-common/src/types/JobRequirement';
import { useUpdateJob } from './Jobs.hook';
import Recipient from 'elitegrad-common/src/types/Recipient';

moment.updateLocale('en', {
  relativeTime: {
    future: '%s left',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
});

const PAGE_SIZE = 4;

const JobsController = () => {
  const [paginationOffset, setPaginationOffset] = useState(INITIAL_OFFSET);
  const [selectedJobId, setSelectedJobId] = useState<string>('');
  const [modalOpenJobDismiss, setModalOpenJobDismiss] = useState(false);
  const [toggleJobModal, setToggleJobModal] = useState(false);
  const [recipientsToSend, setRecipientsToSend] = useState<Recipient[]>([]);
  const { updateJob } = useUpdateJob();

  const { data: dataJobs, loading } = useGetJobsListQuery({
    variables: {
      limit: PAGE_SIZE,
      offset: paginationOffset,
    },
  });

  const jobs = dataJobs?.recruit_job_posting || [];
  const allUsersFromAllJobsIds = Array.from(
    new Set(
      jobs
        .map((job) => job.raise_hands.map((rh) => rh.user_id))
        .filter((raise_hands_ids) => Boolean(raise_hands_ids.length))
        .reduce((ss: string[], c) => [...ss, ...c], []),
    ),
  );

  const { data: dataValidIds } = useGetJobDetailCandidatesValidGPAFromIdsQuery({
    variables: { Ids: allUsersFromAllJobsIds },
  });
  const validGPACandidates = dataValidIds?.grad_profile?.map((candidate) => candidate.user_id) || [];

  const jobsWithAdditionalInfo = jobs.map((j) => ({
    ...j,
    isActive: isActive(j),
    isExpired: isExpired(j),
    isHidden: !j.public,
    relativeDeadline: relativeDeadline(j),
    isInternship: Boolean(asJobType(j.job_type)?.match(/internship/i)) || false,
    candidatesUserIds: [
      ...j.raise_hands.map(({ user_id }) => user_id).filter((user_id) => validGPACandidates.includes(user_id)),
    ],
  }));

  const selectedJobInfo = jobsWithAdditionalInfo.find((job) => job.job_posting_id === selectedJobId);

  const selectedJobData = (): JobObjectType | undefined => {
    if (!selectedJobInfo) return undefined;
    const { job_posting_id, isActive, position_title, description, deadline, region, isInternship, isHidden } =
      selectedJobInfo;

    const requirements = dataJobs?.recruit_job_posting[0].job_requirements
      .map(asJobRequirement)
      .filter((v) => v !== null) as JobRequirement[];

    return {
      id: job_posting_id,
      active: isActive,
      title: position_title,
      firm: '',
      description: description,
      deadline: deadline ? moment(deadline) : null,
      region: region,
      raisedHandsCount: 0,
      candidatesUserIds: [''],
      jobType: isInternship ? JobType.INTERNSHIP : null,
      hidden: isHidden,
      requirements: requirements,
    };
  };

  const onCreateJob = () => {
    setSelectedJobId('');
    setToggleJobModal(true);
  };

  const onEditJob = () => setToggleJobModal(true);

  const handleModalOpen = (open: boolean) => {
    if (!modalOpenJobDismiss) fetchRecipients();
    setModalOpenJobDismiss(open);
  };

  const headerComponent = (
    <HeaderContainer>
      <JobHeader
        title="Jobs"
        jobId={selectedJobId || ''}
        isActive={Boolean(selectedJobInfo?.isActive)}
        isHidden={Boolean(selectedJobInfo?.isHidden)}
        hasCandidates={Boolean(selectedJobInfo?.candidatesUserIds.length)}
        setModalOpen={handleModalOpen}
        hasBackLink={false}
        onCreateJob={onCreateJob}
        onEditJob={onEditJob}
      />
    </HeaderContainer>
  );

  const [fetchRecipients, { data: dataRecipients, loading: loadingRecipients }] =
    useGetJobDismissRecipientsByIdLazyQuery({
      variables: { ids: selectedJobInfo?.candidatesUserIds || [''] },
      onCompleted: () => {
        const recipients = dataRecipients?.grad_profile.map(asRecipient) || [];
        setRecipientsToSend(recipients);
      },
      fetchPolicy: 'network-only',
    });

  const handleDeleteRecipient = (recipientId: string) => {
    const filteredRecipients = recipientsToSend.filter((recipient) => recipient.id !== recipientId);
    setRecipientsToSend(filteredRecipients);
  };

  if (loading) return <SpinnerIsoType text="Loading" />;

  const saveJob = (data: JobObjectType) => {
    updateJob(data);
    setToggleJobModal(false);
  };

  return (
    <>
      <Page headerComponent={headerComponent}>
        <Container>
          <Jobs
            pagination={{
              count: dataJobs?.recruit_job_posting_aggregate.aggregate?.count || 0,
              limit: PAGE_SIZE,
              offset: paginationOffset,
            }}
            jobs={jobsWithAdditionalInfo}
            loadingJobs={loading}
            onChangePaginationOffset={setPaginationOffset}
            selectedJobId={selectedJobId}
            onSelectJob={setSelectedJobId}
            onEditJob={onEditJob}
          />
          {modalOpenJobDismiss && (
            <MessageNewModal
              loading={loadingRecipients}
              setModalOpen={handleModalOpen}
              recipients={recipientsToSend}
              jobId={selectedJobId || ''}
              handleDeleteRecipient={handleDeleteRecipient}
            />
          )}
          {toggleJobModal && (
            <Modal setModalOpen={setToggleJobModal} width="65%">
              <JobEdit job={selectedJobData()} onSave={saveJob} onCancel={() => setToggleJobModal(false)} />
            </Modal>
          )}
          <HorizontalSeparator />
          {selectedJobId ? (
            <JobCandidatesList candidatesUserIds={selectedJobInfo?.candidatesUserIds || []} />
          ) : (
            <PlaceholderContainer>{'Select a Job to see Candidates'}</PlaceholderContainer>
          )}
        </Container>
      </Page>
    </>
  );
};

export default JobsController;
