import React, { useMemo, useState } from 'react';
import EventList from './EventList';
import { useAuth } from 'elitegrad-common/src/utils/react-auth';
import Page from '../../Page';
import Pagination from 'elitegrad-common/src/components/pagination';
import { useGetEventListQuery } from 'elitegrad-common/src/generated/graphql';
import Header from './EventListHeader';
import { PaginationWrapper, Container, HeaderContainer, EventsCount, PlaceholderContainer } from './EventList.styled';
import { HorizontalSeparator } from 'views/jobs/JobList/Jobs.styled';
import RelatedCandidatesController from 'components/RelatedCandidates';
import { dateToTimestamp, dateToDateTimeString } from 'utils/dates';
import EventFormModal from '../EventFormModal';
import { useNewEventMutation, useNewEventQuery } from '../NewEvent/NewEvent.hook';
import EventType from 'elitegrad-common/src/types/Event';
import { useEditEventMutation, useEditEventQuery } from '../EditEvent/EditEvent.hook';
import { asFormEvent } from 'elitegrad-common/src/transformers/formEvent';

const pagination = (count: number, offset: number) => {
  return {
    count: count || 0,
    limit: 4,
    offset,
  };
};

const getFormatedDate = (date: Date) => `${dateToDateTimeString(date)}`;

const EventListController = () => {
  const { user } = useAuth();
  const [offset, setOffset] = useState<number>(0);
  const [togglePopUp, setTogglePopUp] = useState(false);
  const [editTogglePopUp, setEditTogglePopUp] = useState(false);
  const { data: companyData } = useNewEventQuery(user?.sub || '');
  const insertEvent = useNewEventMutation();
  const updateEvent = useEditEventMutation();
  const [selectedEventId, setSelectedEventId] = useState<string>('');
  const { data, loading } = useGetEventListQuery({
    variables: { userID: user?.sub || '', limit: 4, offset },
  });

  const selectedEvent = useMemo(
    () => data?.recruit_event.find(({ event_id }) => event_id === selectedEventId),
    [selectedEventId, data],
  );

  const { data: eventInfo } = useEditEventQuery(selectedEventId);

  const count = data?.recruit_event_aggregate?.aggregate?.count || 0;

  const handleTooglePopUp = () => {
    setTogglePopUp(!togglePopUp);
  };

  const handleEditTooglePopUp = () => {
    setEditTogglePopUp(!editTogglePopUp);
  };

  const handleOnSubmit = async (field: EventType) => {
    const companyID = companyData?.recruit_company[0]?.account_id;
    if (!companyID) return;

    await insertEvent({
      variables: {
        ...field,
        capacity: field.capacity || 0,
        userID: user?.sub || '',
        companyID,
        showEvent: true,
        startDate: dateToTimestamp(field.startDate),
        endDate: dateToTimestamp(field.endDate),
      },
      refetchQueries: ['GetEventList'],
    });

    handleTooglePopUp();
  };

  const handleEditOnSubmit = async (fields: EventType) => {
    if (!eventInfo?.recruit_event_by_pk?.event_id) return;

    await updateEvent({
      variables: {
        ...fields,
        capacity: fields.capacity || 0,
        eventID: eventInfo.recruit_event_by_pk.event_id,
        startDate: dateToTimestamp(fields.startDate),
        endDate: dateToTimestamp(fields.endDate),
      },
      refetchQueries: ['GetEventList'],
    });

    handleEditTooglePopUp();
  };

  const defaultValues = eventInfo?.recruit_event_by_pk && asFormEvent(eventInfo.recruit_event_by_pk);

  return (
    <Page
      headerComponent={
        <HeaderContainer>
          <Header
            eventID={selectedEventId}
            isHidden={!selectedEvent?.show_event}
            onEdit={setEditTogglePopUp}
            onCreate={setTogglePopUp}
          />
        </HeaderContainer>
      }
    >
      <Container>
        <EventList
          eventsList={data?.recruit_event || []}
          selectedEventId={selectedEventId}
          onSelectEvent={setSelectedEventId}
          dateFormater={getFormatedDate}
          loading={loading}
        />
        {togglePopUp && (
          <EventFormModal handleClose={handleTooglePopUp} onSubmit={handleOnSubmit} headTitle={'New Event'} />
        )}
        {editTogglePopUp && (
          <EventFormModal
            handleClose={handleEditTooglePopUp}
            onSubmit={handleEditOnSubmit}
            defaultValues={defaultValues}
            headTitle={'Edit title'}
          />
        )}
        <PaginationWrapper>
          <EventsCount> Events ({data?.recruit_event_aggregate.aggregate?.count}) </EventsCount>
          <Pagination pagination={pagination(count, offset)} onPageChange={setOffset} />
        </PaginationWrapper>
        <HorizontalSeparator />
        {selectedEvent ? (
          <RelatedCandidatesController
            candidatesUserIds={selectedEvent?.event_registrations.map(({ user_id }) => user_id)}
          />
        ) : (
          <PlaceholderContainer>Select an Event to see Participants</PlaceholderContainer>
        )}
      </Container>
    </Page>
  );
};

export default EventListController;
