import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { NoCandidateMessage, PaginationWrapper, Container, PlaceholderIcon } from './RelatedCandidates.styled';
import reducer, { initialState } from './RelatedCandidates.reducer';
import Pagination from 'elitegrad-common/src/components/pagination';
import CandidateType from 'elitegrad-common/src/types/Candidate';
import Candidates from './Candidates';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';
import { asCandidatesWithProfileImages } from 'elitegrad-common/src/transformers/candidates';
import { usePhoto } from 'elitegrad-common/src/utils/usePhoto';
import { generateWhereQuery, WhereQueryInterface } from 'elitegrad-common/src/queries/queryUtils';
import { grad_profile_bool_exp, order_by } from 'elitegrad-common/src/generated/globalTypes';
import { useCandidatesLazyQuery, useCvsLazyQuery, useProfileImageLazyQuery } from './RelatedCandidates.hooks';
import { HandInactive } from 'elitegrad-common/src/assets/vectors';
import CandidateGridFooter from 'elitegrad-common/src/components/candidates/CandidateGridFooter';
import { useDownloadCandidateByUsersId } from 'views/candidates/CandidatesList/CandidatesList.hook';

interface JobCandidatesDashboardProps {
  candidatesUserIds: string[];
  optionalQuery?: grad_profile_bool_exp | null;
}

const RelatedCandidatesController: React.FC<JobCandidatesDashboardProps> = ({
  candidatesUserIds,
  optionalQuery = null,
}) => {
  const [downloadingCVs, setDownloadingCVs] = useState<boolean>(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { paginationOffset, resultsCount, showCVs, selectedCandidates } = state;
  const pagination = {
    count: resultsCount,
    offset: paginationOffset,
    limit: 4,
  };

  const [fetchImages, { data: imagesData }] = useProfileImageLazyQuery();
  const [fetchCVs, { data: cvsData, loading: loadingCVs }] = useCvsLazyQuery();

  const onCompletedFetchCandidates = (data) => {
    dispatch({
      type: 'setResultsCount',
      payload: data?.grad_profile_aggregate.aggregate?.count || 0,
    });
    if (data.grad_profile.length) {
      const userIds = data.grad_profile.map((c) => c.user_id);

      if (showPhoto) fetchImages({ variables: { userIds } });
    }
  };

  const [
    fetchCandidates,
    {
      data: candidatesData,
      loading: loadingCandidates,
      fetchMore: fetchMoreCandidates,
      called: hasBeenFetchedCandidates,
      refetch,
    },
  ] = useCandidatesLazyQuery(onCompletedFetchCandidates);

  const candidatesCount = candidatesData?.grad_profile_aggregate.aggregate?.count || 0;

  const { showPhoto } = usePhoto();

  let candidates = asCandidatesWithProfileImages(
    candidatesData?.grad_profile || [],
    showPhoto ? imagesData?.grad_profile || [] : [],
  );

  candidates = candidates.map((can) => {
    const resumeFound = cvsData?.grad_upload_doc?.find((res) => res.user_id === can.userId);
    if (!resumeFound) return can;
    return {
      ...can,
      resume: {
        uploadDocId: resumeFound.upload_doc_id,
        filename: resumeFound.filename,
        hexContent: resumeFound.hex_content,
      },
    };
  });

  const getDinamicQuery = useCallback(() => {
    const userIdQuery: WhereQueryInterface = {
      enabled: true,
      operator: '_and',
      whereQuery: { user_id: { _in: candidatesUserIds } },
    };
    return generateWhereQuery([userIdQuery]) as grad_profile_bool_exp;
  }, [candidatesUserIds]);

  if (!hasBeenFetchedCandidates) {
    fetchCandidates({
      variables: {
        order_by: {
          creation_timestamp: order_by.asc,
        },
        dynamicQuery: optionalQuery ? optionalQuery : getDinamicQuery(),
        limit: 4,
        offset: paginationOffset,
        admin: false,
      },
    });
  }

  const fetchData = useCallback(() => {
    const fetchMore = (query: grad_profile_bool_exp, offset: number, mustShowCVs: boolean) => {
      if (fetchMoreCandidates) {
        fetchMoreCandidates({
          variables: { dynamicQuery: query, offset },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult?.grad_profile.length) {
              const userIds = fetchMoreResult.grad_profile.map((c) => c.user_id);

              if (showPhoto) fetchImages({ variables: { userIds } });

              if (mustShowCVs) fetchCVs({ variables: { userIds } });
            }
            dispatch({
              type: 'setResultsCount',
              payload: fetchMoreResult?.grad_profile_aggregate.aggregate?.count || 0,
            });
            return { ...prev, ...fetchMoreResult };
          },
        });
      }
    };
    const query = optionalQuery ? optionalQuery : getDinamicQuery();
    return fetchMore(query, paginationOffset, showCVs);
  }, [
    showCVs,
    paginationOffset,
    fetchImages,
    fetchCVs,
    fetchMoreCandidates,
    showPhoto,
    getDinamicQuery,
    optionalQuery,
  ]);

  useEffect(() => {
    fetchData();
  }, [optionalQuery, showCVs, fetchData]);

  const onChangePaginationOffset = (offset: number) => dispatch({ type: 'setPaginationOffset', payload: offset });

  const editSelection = (candidate: CandidateType, add: boolean) => {
    if (add) {
      dispatch({ type: 'addCandidateToSelected', payload: candidate });
    } else {
      dispatch({ type: 'removeCandidateFromSelected', payload: candidate });
    }
  };

  const fetchCVsByUsersId = useDownloadCandidateByUsersId(selectedCandidates, setDownloadingCVs);

  const handleOnDownloadCvsClick = () => {
    setDownloadingCVs(true);
    const userIds = selectedCandidates.map((candidate) => candidate.userId);
    fetchCVsByUsersId({
      variables: {
        userIds,
      },
    });
  };

  const clearSelection = () => dispatch({ type: 'removeAllCandidates' });

  if (loadingCVs || loadingCandidates) return <SpinnerIsoType text="Loading" />;
  const hasCandidates = Boolean(candidatesCount);

  if (!loadingCandidates && !hasCandidates)
    return (
      <NoCandidateMessage>
        <PlaceholderIcon src={HandInactive} />
        There are not raised hands yet
      </NoCandidateMessage>
    );

  return (
    <Container>
      <Candidates
        selectedCandidates={selectedCandidates}
        editSelection={editSelection}
        candidates={candidates}
        candidatesCount={candidatesCount}
        onChangePaginationOffset={onChangePaginationOffset}
        refetch={fetchData}
        hasCVCards
      />
      <PaginationWrapper>
        <Pagination pagination={pagination} onPageChange={onChangePaginationOffset} />
      </PaginationWrapper>
      {selectedCandidates.length > 0 && (
        <CandidateGridFooter
          downloadingCVs={downloadingCVs}
          refetch={() => refetch}
          clearSelection={clearSelection}
          selectedCandidates={selectedCandidates}
          onClickDownloadCvs={handleOnDownloadCvsClick}
          cvsCount={selectedCandidates.length}
        />
      )}
    </Container>
  );
};

export default RelatedCandidatesController;
