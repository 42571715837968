import React from 'react';
import { Icon, ShowStatusLink, Text } from './EventStatusEdit.styled';

interface EventStatusEditProps {
  eventID: string;
  onEdit?: (state: boolean) => void;
}

const EventStatusEdit: React.FC<EventStatusEditProps> = ({ onEdit }) => {
  return (
    <ShowStatusLink onClick={() => onEdit && onEdit(true)}>
      <Icon />
      <Text>Edit</Text>
    </ShowStatusLink>
  );
};

export default EventStatusEdit;
