import gql from 'graphql-tag';

import { JobRequirementParts } from './fragments.gql';

export default gql`
  mutation InsertJobRequirements($requirements: [recruit_job_requirement_insert_input!]!) {
    insert_recruit_job_requirement(objects: $requirements) {
      returning {
        ...JobRequirementParts
      }
    }
  }
  ${JobRequirementParts}
`;
