import React from 'react';

import Header from 'elitegrad-common/src/components/Header';
import StatusHidden from 'components/events/EventStatusHidden';
import StatusEdit from 'components/events/EventStatusEdit';
import StatusDelete from 'components/events/EventStatusDelete';
import StatusCreate from 'components/events/EventStatusCreate';

import { HeaderOptions, Title, OptionsSection } from './EventListHeader.styled';

interface EventListHeaderProps {
  eventID: string;
  isHidden: boolean;
  onEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  onCreate: React.Dispatch<React.SetStateAction<boolean>>;
}

const EventListHeader = ({ eventID, isHidden, onEdit, onCreate }: EventListHeaderProps) => {
  return (
    <Header>
      <Title>Events</Title>
      <OptionsSection>
        <HeaderOptions isEventSelected={Boolean(eventID)}>
          <StatusEdit eventID={eventID} onEdit={onEdit} />
          <StatusHidden eventID={eventID} isHidden={isHidden} />
          <StatusDelete eventID={eventID} />
        </HeaderOptions>
        <StatusCreate onClick={() => onCreate(true)}>Create new event</StatusCreate>
      </OptionsSection>
    </Header>
  );
};

export default EventListHeader;
