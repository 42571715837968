import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import {
  PersonIcon,
  JobIcon,
  MessageIcon,
  EventIcon,
  CompanyIcon,
  LogoutIcon,
} from 'elitegrad-common/src/assets/icons';

import {
  SidebarIsotype,
  StyledBottomArea,
  StyledNonFixedWrapper,
  StyledSidebar,
  StyledSidebarItem,
  StyledSidebarItemIcon,
  StyledSidebarItemText,
  StyledTopArea,
} from './SideBar.styled';

import { Paths } from 'routes';

interface SidebarItemProps {
  Image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  fill?: boolean;
  stroke?: boolean;
  text: string;
  to: string;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ Image, fill, stroke, text, to, ...props }) => {
  const active = !!useRouteMatch(to);

  return (
    <Link to={to} {...props}>
      <StyledSidebarItem {...{ active }}>
        <StyledSidebarItemIcon styles={{ active, fill: !!fill, stroke: !!stroke }}>
          <Image />
        </StyledSidebarItemIcon>
        <StyledSidebarItemText>{text}</StyledSidebarItemText>
      </StyledSidebarItem>
    </Link>
  );
};

interface SidebarImpersonationProps {
  Image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  stroke?: boolean;
  fill?: boolean;
  text: string;
}

const Sidebar: React.FC = () => {
  return (
    <StyledNonFixedWrapper>
      <StyledSidebar>
        <SidebarIsotype />
        <StyledTopArea>
          <SidebarItem to={Paths.candidates} Image={PersonIcon} text="Candidates" fill />
          <SidebarItem to={Paths.jobs} Image={JobIcon} text="Jobs" fill />
          <SidebarItem to={Paths.conversations} Image={MessageIcon} text="Messages" fill />
          <SidebarItem to={Paths.events} Image={EventIcon} text="Events" fill />
          <SidebarItem to={Paths.company} Image={CompanyIcon} text="Company" fill />
        </StyledTopArea>
        <StyledBottomArea>
          <SidebarItem to={Paths.authLogout} Image={LogoutIcon} text="" />
        </StyledBottomArea>
      </StyledSidebar>
    </StyledNonFixedWrapper>
  );
};

export default Sidebar;
