import gql from 'graphql-tag';

import { CompanyParts } from './fragments.gql';

export default gql`
  mutation UpdateCompany(
    $companyId: Int!
    $address: String
    $description: String
    $industry: String
    $name: String
    $num_employees: String
    $phone: String
    $website: String
    $logo: String
    $photo: String
  ) {
    update_recruit_company_by_pk(
      pk_columns: { company_id: $companyId }
      _set: {
        address: $address
        description: $description
        industry: $industry
        name: $name
        num_employees: $num_employees
        phone: $phone
        website: $website
        logo: $logo
        photo: $photo
      }
    ) {
      ...CompanyParts
    }
  }
  ${CompanyParts}
`;
