import React from 'react';
import styled from 'styled-components';
import { useAuth } from 'elitegrad-common/src/utils/react-auth';

import Page from '../Page';

import CompanyDisplay from 'elitegrad-common/src/components/company/CompanyDisplay';

const CompanyDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 5rem;
  height: 100%;
`;
interface CompanyProps {
  logo: string | null;
  photo: string | null;
  name: string;
  industry: string;
  description: string;
  website: string;
  phone: string;
  address: string;
  num_employees: string;
  subindustries: string[];
  toggleEdit: (args: boolean) => void;
}

const Company: React.FC<CompanyProps> = ({
  logo,
  photo,
  name,
  industry,
  subindustries,
  description,
  num_employees,
  phone,
  address,
  website,
  toggleEdit,
}) => {
  const { user } = useAuth();

  return (
    <Page>
      <CompanyDiv>
        <CompanyDisplay
          logo={logo}
          subindustries={subindustries}
          industry={industry}
          description={description}
          address={address}
          website={website}
          name={name}
          photo={photo}
          num_employees={num_employees}
          phone={phone}
          isManager={user?.isManager || false}
          toggleEdit={toggleEdit}
        />
      </CompanyDiv>
    </Page>
  );
};

export default Company;
