import React, { useState } from 'react';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import styled from 'styled-components';

import colors from '../theme/colors';

const StyledDatePicker = styled.div`
  .CalendarDay__selected {
    border-color: #e4e7e7;
    background-color: ${colors.white};
    color: ${colors.red};

    color: ${colors.white};
    background-color: ${colors.red};
    font-weight: 700;
  }

  .CalendarMonth_table {
    margin-top: 10px;
  }

  .DateInput {
    width: 100%;
    margin-left: 2px;

    border: none;

    background-color: transparent;
  }

  .DateInput_input {
    border-bottom: unset;

    background-color: transparent;

    font-family: Roboto;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: ${colors.black};
  }

  .SingleDatePicker {
    width: 100%;
  }

  .SingleDatePicker_picker {
    margin-top: -4px;
  }

  .SingleDatePickerInput_calendarIcon {
    position: absolute;
    right: 0;
    top: 50%;

    transform: translateY(-50%);

    :focus {
      outline: none;
    }
  }

  .SingleDatePickerInput_clearDate {
    position: absolute;
    top: 50%;
    right: 36px;

    margin: 0;
    transform: translateY(-50%);

    :focus {
      outline: none;
    }
  }

  .SingleDatePickerInput__withBorder {
    width: 100%;

    border: 1px solid ${colors.gray_l1};
    border-radius: 4px;

    background-color: transparent;
  }
`;

export interface DatePickerProps {
  id: string;
  value: moment.Moment | null;
  onChange?: (value: moment.Moment | null) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({ id, value, onChange = () => undefined }) => {
  const [focused, setFocused] = useState(false);

  return (
    <StyledDatePicker>
      <SingleDatePicker
        id={id}
        placeholder=""
        date={value}
        onDateChange={onChange}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused || false)}
        numberOfMonths={1}
        showDefaultInputIcon
        showClearDate={true}
        inputIconPosition="after"
        hideKeyboardShortcutsPanel
        verticalSpacing={0}
      />
    </StyledDatePicker>
  );
};

export default DatePicker;
