import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { useImpersonation } from '../utils/useImpersonation';
import SpinnerIsoType from '../components/SpinnerIsoType';

const ImpersonRoute = () => {
  const { id } = useParams<{ id: string }>();
  const { setImpersonationProps } = useImpersonation();
  const history = useHistory();

  useEffect(() => {
    setImpersonationProps(id, 'recruiter');
    history.push('/');
    history.go(0);
  }, []);

  return <SpinnerIsoType fullPage text="Impersonating" />;
};

export default ImpersonRoute;
