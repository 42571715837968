import React, { Dispatch, SetStateAction } from 'react';

import JobCard from './JobCard/JobCard';
import { JobsCount, PaginationWrapper, JobCardsGrid, Placeholder, PlaceholderIcon } from './Jobs.styled';

import Pagination, { PaginationState } from 'elitegrad-common/src/components/pagination';
import { GetJobsListPartsFragment } from 'elitegrad-common/src/generated/graphql';
import { NoWorkExperiencePlaceholder } from 'elitegrad-common/src/assets/icons';

interface JobTypeDos extends GetJobsListPartsFragment {
  candidatesUserIds: string[];
  isActive: boolean;
  isExpired: boolean;
  isHidden: boolean;
  isInternship: boolean;
  relativeDeadline: string | null;
}

interface JobsProps {
  jobs: JobTypeDos[];
  loadingJobs: boolean;
  pagination: PaginationState;
  onChangePaginationOffset: (offset: number) => void;
  selectedJobId?: string;
  onSelectJob?: Dispatch<SetStateAction<string>>;
  onEditJob?: () => void;
}

const Jobs: React.FC<JobsProps> = ({
  jobs,
  loadingJobs,
  pagination,
  onChangePaginationOffset,
  selectedJobId,
  onSelectJob,
  onEditJob,
}) => {
  return (
    <>
      {Boolean(jobs.length) ? (
        <JobCardsGrid>
          {jobs.map((job) => (
            <JobCard
              key={job.job_posting_id}
              id={job.job_posting_id}
              candidatesUserIds={job.candidatesUserIds}
              isInternship={job.isInternship}
              region={job.region}
              description={job.description}
              isActive={job.isActive}
              isHidden={job.isHidden}
              isExpired={job.isExpired}
              relativeDeadline={job.relativeDeadline}
              title={job.position_title}
              isSelected={selectedJobId === job.job_posting_id}
              onSelectCard={onSelectJob}
              onEditJob={onEditJob}
            />
          ))}
        </JobCardsGrid>
      ) : (
        <Placeholder>
          <PlaceholderIcon src={NoWorkExperiencePlaceholder} />
          {'No Job created yet'}
        </Placeholder>
      )}
      <PaginationWrapper>
        <JobsCount>{loadingJobs ? '' : `Jobs (${pagination.count})`}</JobsCount>
        <Pagination pagination={pagination} onPageChange={onChangePaginationOffset} />
      </PaginationWrapper>
    </>
  );
};

export default Jobs;
