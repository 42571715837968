import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import Select from 'react-select';

import { selectionAsInterestFirm } from '../transformers/interestFirmsSelection';
import GET_INTEREST_FIRM_SELECTION from '../queries/getInterestFirmSelection.gql';
import { GetInterestFirmSelection } from '../generated/GetInterestFirmSelection';

import InterestFirmType from '../types/InterestFirm';
import Spinner from './Spinner';
import { selectorStyles } from '../theme';

export interface SubIndustrySelectorProps {
  values?: string[] | null;
  onChange?: (value?: string[] | null) => void;
}

const SubIndustrySelector: React.FC<SubIndustrySelectorProps> = ({ values = [], onChange = () => undefined }) => {
  const { data, loading } = useQuery<GetInterestFirmSelection>(GET_INTEREST_FIRM_SELECTION);

  const firmTypes: InterestFirmType[] =
    data?.grad_interest_firm_selection.map((gp) => selectionAsInterestFirm(gp)) || [];
  const options = useMemo(
    () =>
      firmTypes.map(({ firmType }) => ({
        value: firmType,
        label: firmType,
      })),
    [firmTypes],
  );
  const valuesOptions = values?.map((val) => ({ value: val, label: val }));

  const [selectedOptions, setSelectedOptions] = useState(valuesOptions || []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChange = (value: any, { action, removedValue: removedOption, option: addedOption }: any) => {
    let newValue = selectedOptions;
    switch (action) {
      case 'select-option':
        newValue = [...selectedOptions, addedOption];
        setSelectedOptions(newValue);
        break;
      case 'remove-value':
        newValue = selectedOptions.filter(({ value: currentValue }) => currentValue !== removedOption.value);
        setSelectedOptions(newValue);
        break;
    }
    onChange(newValue.map(({ value }) => value));
  };

  return loading ? (
    <Spinner />
  ) : (
    <Select
      defaultValue={selectedOptions}
      options={options}
      onChange={handleOnChange}
      isClearable
      isMulti
      styles={selectorStyles}
    />
  );
};
export default SubIndustrySelector;
