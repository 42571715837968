import React from 'react';
import Page from '../../Page';
import Form from 'components/events/EventForm';

import Header from 'elitegrad-common/src/components/Header';
import EventType from 'elitegrad-common/src/types/Event';

interface NewEventProps {
  submit: (data: EventType) => void;
  onCancel: () => void;
  defaultValues?: EventType | null;
}

const NewEvent: React.FC<NewEventProps> = ({ submit, onCancel }) => {
  return (
    <Page headerComponent={<Header backLink={true} />}>
      <Form submit={submit} onCancel={onCancel} />
    </Page>
  );
};

export default NewEvent;
