import Job from 'elitegrad-common/src/types/Job';
import { useQuery, useMutation } from '@apollo/client';
import { CreateJob, CreateJobVariables } from 'elitegrad-common/src/generated/CreateJob';
import { GetCompanyOld, GetCompanyOldVariables } from 'elitegrad-common/src/generated/GetCompanyOld';
import { recruit_job_requirement_insert_input } from 'elitegrad-common/src/generated/globalTypes';
import { asCompany } from 'elitegrad-common/src/transformers/companies';
import { asJobRequirementParts } from 'elitegrad-common/src/transformers/jobRequirements';
import { useAuth, User } from 'elitegrad-common/src/utils/react-auth';
import moment from 'moment';
import CREATE_JOB from 'elitegrad-common/src/queries/createJob.gql';
import GET_COMPANY from 'elitegrad-common/src/queries/getCompany.gql';
import {
  RemoveJobRequirements,
  RemoveJobRequirementsVariables,
} from 'elitegrad-common/src/generated/RemoveJobRequirements';
import REMOVE_JOB_REQUIREMENTS from 'elitegrad-common/src/queries/removeJobRequirements.gql';
import INSERT_JOB_REQUIREMENTS from 'elitegrad-common/src/queries/insertJobRequirements.gql';
import UPDATE_JOB from 'elitegrad-common/src/queries/updateJob.gql';
import {
  InsertJobRequirements,
  InsertJobRequirementsVariables,
} from 'elitegrad-common/src/generated/InsertJobRequirements';
import { UpdateJob, UpdateJobVariables } from 'elitegrad-common/src/generated/UpdateJob';

const useCreateJob = () => {
  const { user } = useAuth() as { user: User };

  const { data: dataCompany } = useQuery<GetCompanyOld, GetCompanyOldVariables>(GET_COMPANY, {
    skip: !user?.sub,
    variables: {
      id: user?.sub || '',
    },
  });

  const [create] = useMutation<CreateJob, CreateJobVariables>(CREATE_JOB, { refetchQueries: ['GetJobsList'] });

  const handleCreate = async (job: Job) => {
    const company = dataCompany?.recruit_company.map((c) => asCompany(c))[0] || null;

    if (!company?.accountId) return;
    const requirements = (job.requirements || [])
      .map((r) => asJobRequirementParts(r, job.id))
      .filter((r) => r !== null) as recruit_job_requirement_insert_input[];

    const variables = {
      account_id: company.accountId,
      creator_id: user.sub,
      active: true,
      creation_date: moment().toDate(),
      description: job.description,
      firm: company.name,
      job_type: job.jobType as string,
      position_title: job.title,
      deadline: job.deadline?.toDate(),
      region: job.region,
      requirements,
    };

    create({ variables });
  };

  return { handleCreate };
};

const useEditJob = () => {
  const [update] = useMutation<UpdateJob, UpdateJobVariables>(UPDATE_JOB, { refetchQueries: ['GetJobsList'] });
  const [removeRequirements] = useMutation<RemoveJobRequirements, RemoveJobRequirementsVariables>(
    REMOVE_JOB_REQUIREMENTS,
  );

  const [insertRequirements] = useMutation<InsertJobRequirements, InsertJobRequirementsVariables>(
    INSERT_JOB_REQUIREMENTS,
  );

  const handleEdit = (job: Job) => {
    const variables = {
      job_posting_id: job.id,
      description: job.description,
      position_title: job.title,
      job_type: job.jobType as string,
      deadline: job.deadline?.toDate(),
      region: job.region,
    };
    removeRequirements({ variables: { jobId: job.id } });
    const requirements = job.requirements
      .map((r) => asJobRequirementParts(r, job.id))
      .filter((r) => r !== null) as recruit_job_requirement_insert_input[];
    insertRequirements({ variables: { requirements } });
    update({ variables });
  };

  return { handleEdit };
};

export const useUpdateJob = () => {
  const { handleCreate } = useCreateJob();
  const { handleEdit } = useEditJob();

  const updateJob = (job: Job) => {
    !job.id ? handleCreate(job) : handleEdit(job);
  };

  return { updateJob };
};
