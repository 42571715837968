import gql from 'graphql-tag';

import { RegionParts } from './fragments.gql';

export default gql`
  query GetRegions {
    grad_interest_region_selection {
      ...RegionParts
    }
  }
  ${RegionParts}
`;
