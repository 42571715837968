import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Job from 'elitegrad-common/src/types/Job';
import EditJobForm from '../jobs/JobDetail/EditJobForm';

interface NewJobProps {
  onSave: (job: Job) => void;
}

const NewJob: React.FC<NewJobProps> = ({ onSave }) => {
  const history = useHistory();

  const handleOnSave = useCallback(
    async (thejob: Job) => {
      await onSave(thejob);
      history.goBack();
    },
    [history, onSave],
  );

  return <EditJobForm onCancel={history.goBack} onSave={handleOnSave} />;
};

export default NewJob;
