import React from 'react';

import { StyledText, StyledIcon, StyledShowStatusLink } from './JobStatusEdit.styled';

interface JobStatusEditProps {
  onEditJob?: () => void;
}

const JobStatusEdit = ({ onEditJob }: JobStatusEditProps) => {
  return (
    <StyledShowStatusLink onClick={() => onEditJob && onEditJob()}>
      <StyledIcon />
      <StyledText>Edit</StyledText>
    </StyledShowStatusLink>
  );
};

export default JobStatusEdit;
