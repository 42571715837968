import gql from 'graphql-tag';

export default gql`
  mutation DeleteJob($jobId: String!) {
    delete_recruit_job_posting(where: { job_posting_id: { _eq: $jobId } }) {
      returning {
        job_posting_id
      }
    }
  }
`;
