import React from 'react';
import { StyledItemName, StyledItemValue } from './ProfileInformationItem.styled';

interface ProfileInformationItemProps {
  text: string;
  value: string;
}
const ProfileInformationItem: React.FC<ProfileInformationItemProps> = ({ text, value }) => {
  return (
    <div>
      <StyledItemName>{text}:</StyledItemName>
      <StyledItemValue>{value}</StyledItemValue>
    </div>
  );
};

export default ProfileInformationItem;
