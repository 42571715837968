import moment from 'moment';
import { GetJobsListPartsFragment } from 'elitegrad-common/src/generated/graphql';

const isActive = (job: GetJobsListPartsFragment) => {
  return !isExpired(job) && job.active;
};

const isExpired = (job: GetJobsListPartsFragment) => {
  if (!job.deadline) {
    return false;
  }

  return moment(job.deadline).isBefore(moment());
};

const relativeDeadline = (job: GetJobsListPartsFragment) => {
  if (!job.deadline) {
    return null;
  }

  return moment(job.deadline).endOf('day').fromNow();
};

export { isActive, isExpired, relativeDeadline };
