import styled from 'styled-components';
import { colors } from 'elitegrad-common/src/theme';
import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import VerticalDivider from 'elitegrad-common/src/components/VerticalDivider';

export const OptionsSection = styled.div`
  display: flex;

  min-width: 45rem;
  min-height: 3.5rem;
`;

export const JobOptions = styled.div<{ isJobSelected?: boolean }>`
  display: flex;
  opacity: ${({ isJobSelected }) => (isJobSelected ? '100%' : '20%')};
  ${({ isJobSelected }) => !isJobSelected && 'pointer-events: none;'}

  & > div,
  a {
    border-right: 1px solid ${colors.gray_light_l2};
    padding: 0 3rem;
  }

  & > div:last-of-type {
    border-right: none;
  }
`;

export const StyledVerticalDivider = styled(VerticalDivider)`
  background: ${colors.gray_light_l2};
`;

export const Title = styled.div`
  font-family: 'RobotoBold';
  font-size: 3.4rem;
`;

export const HeaderButton = styled(PrimaryButton)`
  margin-left: auto;
  border-radius: 6px;
  font-weight: 400;
  min-height: 3.6rem;
`;
